
export default function JiraCellRenderer() {
}

JiraCellRenderer.prototype.init = function (params) {
    console.log('URLCellRenderer.prototype.init', params);
   
    if (params.value) {
        var span = document.createElement('span');
        var a = document.createElement('a');
        a.innerText  = params.value;
        a.target = "_blank";
        a.href = "https://jira.panasonic.aero/browse/" + params.value;
        span.appendChild(a);
        this.eGui = span;
    }
    else this.eGui ="";
};

JiraCellRenderer.prototype.getGui = function () {
    return this.eGui;
};


import React from 'react';

import { Logger }                   from 'aws-amplify';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';

const logger = new Logger('FormElements/Select');

export default function Select(props) {
  logger.debug("props", props);

  var options_init;

  /*
  if (props.data) {
    options_init = props.data; //not sure why this was being set this way.
  
  }
  else 
  */
  if (props.options) {
    options_init = props.options;
  }
  else {
    options_init = [];
  }
  
  const default_value =  props.defaultValue ? props.defaultValue : null;
  const query_string_params = JSON.stringify(props.queryStringParameters);

  const [open, setOpen]             = React.useState(false);
  const [options, setOptions]       = React.useState(options_init);
  const [value, setValue]           = React.useState(default_value);
  const [inputValue, setInputValue] = React.useState("");
  const [loaded, setLoaded]         = React.useState(false);
  const [loading, setLoading]       = React.useState(false);
  const [qsp, setQSP]               = React.useState();
  
 // const [qsparams, setQSParams]         = React.useState(JSON.stringify(props.queryStringParameters));
  //logger.debug('qsparams', qsparams);
  
//  const loading = open && !loaded && props.service;
  
    logger.debug("value", value, default_value, loading);

  React.useEffect(() => {
    logger.debug("useEffect", props, loaded, loading, default_value, qsp, query_string_params);
    if (loaded && query_string_params == qsp) return;
    setQSP(query_string_params);
    setValue(default_value);
    setLoaded(false);
    //setOptions([]);
    if (!props.service) {
      //setOptions([]);
      setLoaded(false);
    }
    else {
      setOptions([]);
    }
    
    /*

    
    let active = true;
   
    if (!loading) {
      logger.debug("useEffect not loading");
      return undefined;
    }

    if (active) {
      
      
     
      if (!loaded && props.service) { 
        logger.debug("active2", props.options);
        props.service.fetchRecords(props.queryStringParameters)
          .then((data) => {
            logger.debug("success", data);  
            //var new_options = [];
            //data['data'].forEach(e => {
            //  new_options.push(props.getValue(e));
            //});
            var new_data = data['data'];
            if (props.service.formatRowData) {
              new_data = props.service.formatRowData(data['data']);
            }
            setOptions(new_data);
            setLoaded(true);
          }).catch((err) => {
            logger.debug("error", err);
            setOptions([]);
          }); 
    
      }
    }

    return () => {
      active = false;
    };
    */
  }, [default_value, query_string_params]);

     if (open && !loaded && !loading && props.service) { 
       setLoading(true);
          logger.debug("active2", props.options);
          props.service.fetchRecords(props.queryStringParameters)
            .then((data) => {
              logger.debug("success 1", data);  
              //var new_options = [];
              //data['data'].forEach(e => {
              //  new_options.push(props.getValue(e));
              //});
             
              if (props.service.formatRowData) {
                logger.debug("success props.service.formatRowData", data);
                
                var new_data = data['data'];
                new_data = props.service.formatRowData(new_data);
              }
              if (props.formatRowData) {
                logger.debug("success formatRowData", data);
                new_data = props.formatRowData(data);
              } 
              
              if(!new_data) {
                new_data = data?.data && data.data
              } 
              
              logger.debug("success new_data", new_data);  
              
              setOptions(new_data);
              setLoaded(true);
              setLoading(false);
            }).catch((err) => {
              logger.debug("error", err);
              setOptions([]);
              setLoaded(true);
              setLoading(false);
            }); 
      
        }
      
   logger.debug("Options", options, inputValue, value);

  return (
   
    <Autocomplete
      style={{display: "inline"}}
      id={props.id}
      clearOnBlur={false}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      disableClearable = {props.disableClearable}
      disabled={props.disabled===true?true:false}
      //isOptionEqualToValue={(option, value) => option.operator_name === props.defaultValue.operator_name} // not supported in this version of Material UI
      value={value}
      onChange={(event, newValue) => {
        logger.debug('onChange', event, newValue);  
        setValue(newValue);
        if (newValue)
          props.handleChange({target: {name: props.name, value: newValue}});
        else 
          props.handleChange({target: {name: props.name, value: null}});
        
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        logger.debug('onInputChange', event, newInputValue);  
        setInputValue(newInputValue);
        //setValue(newInputValue);
        if (props.allowFreeText)
          props.handleChange({target: {name: props.name, value: newInputValue}});
      }}
      options={options}
      loading={loading}
      getOptionLabel={props.getOptionLabel}
      renderOption={props.renderOption}
      noOptionsText={props.noOptionsText?props.noOptionsText:"No Options"}
      renderInput={(params) => {
        const inputProps = params.inputProps;
        inputProps.autoComplete = 'off'; //"new-password";
        return (
          <TextField
            {...params}
            label={props.label}
            required={props.required}
            variant={props.variant?props.variant:"outlined"}
            style={props.style}
            error={props.error}
            helperText={props.helperText}
            autoComplete="new-password"
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off', //'new-password', // disable html autocomplete and autofill see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
import OauthService from "./OauthService";
import BaseService from "./BaseService";
import React, { Component, useState } from "react";
import { Logger } from "aws-amplify";
import BooleanCellRenderer from "../components/AGGrid/BooleanCellRenderer";
//import cron         from 'cron-validate'; // https://www.npmjs.com/package/cron-validate

import attribute_data from "../data/FilterAttributes";

import {
  Menu,
  MenuItem,
  IconButton,
  MenuList,
  Paper,
  Popper,
  ClickAwayListener,
  Grow,
} from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const logger = new Logger("FieldSupportService");

const checkboxes = ["enabled"];

const dropdownAction = [
  "None",
  "ClearClientCache",
  "PasswordChange",
  "Suspended",
  "InvalidSession",
  "Expired",
];

var cron_options = [
  { name: "Hourly", value: "0 * * * *", default: true },
  { name: "Daily", value: "0 23 * * *" },
  { name: "Weekly", value: "0 23 * * 0" },
  { name: "Monthly", value: "0 23 1 * *" },
];

const mockAPIFieldSupportData = [
  {
    rule_number: "H-4db40576-8a2b-4ccd-9b1f-03ae573f8bcf",
    rule_name: "Headend - Hardware No Response",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "N343NB",
    operator: "DAL",
    ac_type: "787",
    total_hits: 10,
    total_flights: 5,
    severity_prediction: "90%",
  },
  {
    rule_number: "H-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Headend - Degraded Laser Power",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "N075UA",
    operator: "UAL",
    ac_type: "A320",
    total_hits: 7,
    total_flights: 2,
    severity_prediction: "70%",
  },
  {
    rule_number: "H-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Headend - Link break",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "A6-EIU",
    operator: "ETD",
    ac_type: "A380",
    total_hits: 5,
    total_flights: 3,
    severity_prediction: "60%",
  },
  {
    rule_number: "H-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Headend - Software application watchdog",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "C-GROV",
    operator: "ACN",
    ac_type: "737",
    total_hits: 15,
    total_flights: 6,
    severity_prediction: "10%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - EnableFrontRJ45",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "B-17811",
    operator: "EVA",
    ac_type: "787",
    total_hits: 10,
    total_flights: 7,
    severity_prediction: "25%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - CreateOffload",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "A7-BAZ",
    operator: "QTR",
    ac_type: "A320",
    total_hits: 15,
    total_flights: 1,
    severity_prediction: "10%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - ExConnSetWapRadioStatus",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "GCS037",
    operator: "TST",
    ac_type: "737",
    total_hits: 35,
    total_flights: 2,
    severity_prediction: "65%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - StartBiteTest",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "CC-BGB",
    operator: "LAN",
    ac_type: "747",
    total_hits: 40,
    total_flights: 3,
    severity_prediction: "20%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - ResetSeatByRange",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "B-HLM",
    operator: "CPA",
    ac_type: "757",
    total_hits: 67,
    total_flights: 6,
    severity_prediction: "15%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - Interactive Seat Reset",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "N14115",
    operator: "UAL",
    ac_type: "777",
    total_hits: 13,
    total_flights: 9,
    severity_prediction: "2%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - Internet Access Control",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "EI-LRD",
    operator: "EIN",
    ac_type: "A320",
    total_hits: 11,
    total_flights: 3,
    severity_prediction: "5%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - PED Power Control",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "SU-GFL",
    operator: "MSR",
    ac_type: "A380",
    total_hits: 23,
    total_flights: 4,
    severity_prediction: "9%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - USB Power Control",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "JA462A",
    operator: "ANA",
    ac_type: "777",
    total_hits: 9,
    total_flights: 4,
    severity_prediction: "33%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - Full Seat Reset",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "D-AXGF",
    operator: "OCN",
    ac_type: "787",
    total_hits: 5,
    total_flights: 6,
    severity_prediction: "50%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Opportunity Spike",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "UAL",
    ac_type: "757",
    total_hits: 1000,
    total_flights: 8,
    severity_prediction: "25%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Opportunity for Invalid Zones",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "FIN",
    ac_type: "747",
    total_hits: 1500,
    total_flights: 9,
    severity_prediction: "45%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Opportunity for Invalid Sizes",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "ICE",
    ac_type: "A320",
    total_hits: 1222,
    total_flights: 8,
    severity_prediction: "88%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Impression for active campaigns",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "JAL",
    ac_type: "A380",
    total_hits: 5672,
    total_flights: 4,
    severity_prediction: "97%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Hardware No Response+Maint - ResetSeatByRange",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "N14115",
    operator: "UAL",
    ac_type: "777",
    total_hits: 11,
    total_flights: 3,
    severity_prediction: "10%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Degraded Laser Power+QT CM GUI - USB Power Control",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "EI-LRD",
    operator: "EIN",
    ac_type: "A320",
    total_hits: 13,
    total_flights: 5,
    severity_prediction: "22%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Maint - ResetSeatByRange+QT CM GUI - Interactive Seat Reset",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "SU-GFL",
    operator: "MSR",
    ac_type: "A380",
    total_hits: 22,
    total_flights: 9,
    severity_prediction: "33%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Link break+QT CM GUI - Internet Access Control",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "JA462A",
    operator: "ANA",
    ac_type: "777",
    total_hits: 6,
    total_flights: 5,
    severity_prediction: "45%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Hardware No Response+QT CM GUI - Full Seat Reset",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "D-AXGF",
    operator: "OCN",
    ac_type: "787",
    total_hits: 20,
    total_flights: 4,
    severity_prediction: "65%",
  },
];
const menuItems = [
  {
    label: "Create a KONOS ticket",
    onClick: () => null,
  },
  {
    label: "Further investigation needed",
    onClick: () => null,
  },
  {
    label: "Critical - Requires AC in-person visit",
    onClick: () => null,
  },
  {
    label: "Assign to Data team",
    onClick: () => null,
  },
  {
    label: "Assign to Core-Engineering team",
    children: [
      { label: "One Media", onClick: () => null },
      { label: "IFC SW", onClick: () => null },
      { label: "HWE", onClick: () => null },
      { label: "Headend", onClick: () => null },
      { label: "One Media", onClick: () => null },
    ],
  },
];

const ActionsRenderer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
    setActiveSubmenu(null);
  };

  const handleSubmenuOpen = (event, index) => {
    setSubmenuAnchorEl(event.currentTarget);
    setActiveSubmenu(index);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
    setActiveSubmenu(null);
  };

  return (
    <div>
      <div onClick={handleMenuOpen} variant="contained">
        {menuItems[Math.floor(Math.random() * menuItems.length)].label}
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map((item, index) => (
          <div key={index}>
            {item.children ? (
              <MenuItem
                onMouseEnter={(event) => handleSubmenuOpen(event, index)}
                onMouseLeave={handleSubmenuClose}
              >
                {item.label}
                <Menu
                  anchorEl={submenuAnchorEl}
                  open={activeSubmenu === index}
                  onClose={handleSubmenuClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  {item.children.map((subItem, subIndex) => (
                    <MenuItem key={subIndex} onClick={subItem.onClick}>
                      {subItem.label}
                    </MenuItem>
                  ))}
                </Menu>
              </MenuItem>
            ) : (
              <MenuItem onClick={item.onClick}>{item.label}</MenuItem>
            )}
          </div>
        ))}
      </Menu>
    </div>
  );
};

export function DynamicDropdown() {
  return <ActionsRenderer />;
}
export default class FieldSupportService extends BaseService {
  constructor() {
    let apiName = "FieldSupportAPI";
    let collectionPath = "/field_support";
    super(apiName, collectionPath);
  }

  /**
   * This is for loading dummy data  Remove it once you have the real data
   */
  async fetchRecords(recordId, params = {}) {
    return Promise.resolve(mockAPIFieldSupportData);
  }

  /**
   * CSVToArray parses any String of Data including '\r' '\n' characters,
   * and returns an array with the rows of data.
   * @param {String} CSV_string - the CSV string you need to parse
   * @param {String} delimiter - the delimeter used to separate fields of data
   * @returns {Array} rows - rows of CSV where first row are column headers
   */
  static csvToArrayOfObjects(CSV_string, delimiter) {
    delimiter = delimiter || ","; // user-supplied delimeter or default comma

    var pattern = new RegExp( // regular expression to parse the CSV values. // Delimiters:
      "(\\" +
        delimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        delimiter +
        "\\r\\n]*))",
      "gi"
    );

    var rows = [[]]; // array to hold our data. First row is column headers.
    // array to hold our individual pattern matching groups:
    var matches = false; // false if we don't find any matches
    // Loop until we no longer find a regular expression match
    while ((matches = pattern.exec(CSV_string))) {
      var matched_delimiter = matches[1]; // Get the matched delimiter
      // Check if the delimiter has a length (and is not the start of string)
      // and if it matches field delimiter. If not, it is a row delimiter.
      if (matched_delimiter.length && matched_delimiter !== delimiter) {
        // Since this is a new row of data, add an empty row to the array.
        rows.push([]);
      }
      var matched_value;
      // Once we have eliminated the delimiter, check to see
      // what kind of value was captured (quoted or unquoted):
      if (matches[2]) {
        // found quoted value. unescape any double quotes.
        matched_value = matches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // found a non-quoted value
        matched_value = matches[3];
      }
      // Now that we have our value string, let's add
      // it to the data array.
      rows[rows.length - 1].push(matched_value);
    }

    logger.debug("csvToArrayOfObjects", rows);
    return rows;
  }

  /**
   * Look up the header ids from the titles
   * Then turn each row into an key/value object
   */
  static parseRawObject(rows) {
    var header = rows[0];
    logger.debug("parseRawObject1", header);
    var colDefs = FieldSupportService.getColDefs();
    var new_data = [];
    if (header) {
      logger.debug("parseRawObject1a");
      var header_fields = [];
      header.forEach((header_text) => {
        var group_header_name = header_text
          .substring(0, header_text.indexOf(":"))
          .trim();
        var header_name = (header_text = header_text
          .substring(header_text.indexOf(":") + 1)
          .trim());

        var group = colDefs.filter((obj) => {
          return obj["headerName"] === group_header_name;
        })[0];
        if (group && group.children) {
          var child = group.children.filter((obj) => {
            return obj["headerName"] === header_name;
          })[0];
          if (child) {
            header_fields.push(child.field);
          }
        }
      });
      for (var i = 1; i < rows.length; i++) {
        var new_row = {};
        header_fields.forEach((field, index) => {
          logger.debug("parseRawObject3", field, index, rows[i][index]);
          new_row[field] = rows[i][index];
        });
        new_data.push(new_row);
      }
      logger.debug("parseRawObject", new_data);
    }
    return new_data;
  }

  formatRowData(data) {
    logger.debug("formatRowData0", data, data.data);
    return data;
  }

  getMetaData(data) {
    logger.debug("getMetaData", data);
    return {
      pagination: false,
      total_records: data.length,
    };
  }

  getCloneRecord(record) {
    record["filter_name"] = record["filter_name"] + " (clone)";
    record["original_title"] = "";
    record["last_updated_date"] = null;
    return record;
  }

  /*
  uploadData(data) {
    logger.debug("uploadData", data);
    try {
      var json = JSON.parse(data);
    } catch (e) {
      logger.debug("uploadData error", e);
      json = FieldSupportService.parseRawObject(
        FieldSupportService.csvToArrayOfObjects(data)
      );
    }
    if (json.length) {
      var response = "";
      var response_count = 0;
      json.forEach((element) => {
        if (element["filter_name"]) {
          response_count++;
          response += element["filter_name"] + "\n";
        }
      });
      response += "Records processed:  " + response_count;
      return response;
    } else {
      return "bad data";
    }
  }
  */

  async delete(data, recordID) {
    logger.debug("delete", data, recordID);
    var response = null;
    // send array of all names. e.g. ['myname', 'another_name'] to the DELETE
    try {
      var results = await this.deleteRecord("", [data["filter_name"]]);
      response = {
        success: true,
        updated: true,
        data: results,
      };
    } catch (err) {
      response = {
        success: false,
        updated: false,
        data: results,
      };
    }
    return response;
  }

  /**
   * {
   *   "enabled": false,
   *   "filter_name": "filter",
   *   "filter_description": "Updated description",
   *   "filter_query": "{updated query}",
   *   "user": "someother_user@pansonic.aero"
   * }
   */
  async save(data, metaData) {
    logger.debug("save1", data, metaData);

    //check the PK and set bool to true/false
    checkboxes.forEach((checkbox) => {
      if (data[checkbox]) data[checkbox] = true;
      else data[checkbox] = false;
    });

    var new_data = {};

    var query_json;
    try {
      query_json = JSON.parse(data["filter_query"]);
    } catch (e) {
      return {
        success: false,
        updated: false,
        response: {
          data: {
            error: "You have an error in your rule",
          },
          columns: [
            {
              key: "filter_query",
              message: "Error in rule",
            },
          ],
        },
      };
    }
    for (const [key, value] of Object.entries(query_json)) {
      if (value["rules"].length > 0) {
        attribute_data.forEach((e) => {
          if (e["source"] === key) {
            new_data["data_set"] = e["data_set"];
          }
        });
      }
    }

    new_data["user"] = metaData.app_state.user.session.login_name;
    new_data["filter_name"] = data["filter_name"];
    new_data["filter_description"] = data["filter_description"]
      ? data["filter_description"]
      : "";
    new_data["filter_query"] = data["filter_query"];
    new_data["enabled"] = data["enabled"];
    new_data["cron_rule"] = data["cron_rule"].value;
    new_data["filter_team"] = data["filter_team"];

    logger.debug("save2", data, new_data, metaData);
    var response = null;
    if (data["last_updated_date"]) {
      //update because it already had a date
      logger.debug("save update", data);
      try {
        var results = await this.update("", new_data);
        response = {
          success: true,
          updated: true,
          data: results,
        };
      } catch (err) {
        //.catch((err) => {
        logger.debug("save: update record failed", err, new_data);
        response = {
          success: false,
          updated: false,
          response: {
            data: {
              error: "An error occurred",
            },
          },
        };

        //});
      }
    } else {
      //insert
      logger.debug("save insert", new_data);
      await this.add(new_data)
        .then((results) => {
          logger.debug("save: insert record", results);
          if (results.success == false) {
            response = {
              success: false,
              updated: false,
              response: {
                data: {
                  error: results.error.message,
                },
              },
            };
          } else {
            response = {
              success: true,
              updated: true,
            };
          }
        })
        .catch((err) => {
          logger.debug("save: insert record error1", err);
          response = {
            success: false,
            updated: false,
            response: {
              data: {
                error: err.message,
              },
            },
          };
        });
    }
    return response;
  }

  getColumnDefs() {
    return FieldSupportService.getColDefs();
  }

  /**
   * This needs to be static to have the other static functions access it.
   */
  static getColDefs() {
    var column_width = 100;

    return [
      {
        headerName: "Details",
        defaultExpanded: true,

        children: [
          {
            field: "rule_name",
            headerName: "Rule Name",
            filter: "agTextColumnFilter",
            width: column_width * 2,
            resizable: true,
            formType: "text",
            required: true,
          },
          {
            field: "rule_type",
            headerName: "Rule Type",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "select",
            options: ["Anomaly Rule", "C-Rule"],
            required: true,
          },
          {
            field: "team",
            headerName: "Team",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "select",
            options: ["Headend", "Maint", "CMI GUI"],
            required: true,
          },
          {
            field: "action",
            headerName: "Action",
            filter: "agTextColumnFilter",
            cellRendererFramework: DynamicDropdown,
            width: 200,
            resizable: true,
            formType: "text",
            required: false,
          },
          {
            field: "tail_number",
            headerName: "Tail Number",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "text",
            required: false,
          },
          {
            field: "total_hits",
            headerName: "Total Hits",
            filter: "agNumberColumnFilter",
            width: column_width,
            resizable: true,
            formType: "number",
            required: false,
          },
          {
            field: "total_flights",
            headerName: "Total Flights",
            filter: "agNumberColumnFilter",
            width: column_width,
            resizable: true,
            formType: "number",
            required: false,
          },
          {
            field: "severity_prediction",
            headerName: "Severity Prediction(%)",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "text",
            required: false,
          },
          {
            field: "operator",
            headerName: "Operator",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "text",
            required: false,
          },
          {
            field: "ac_type",
            headerName: "AC Type",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "text",
            required: false,
          },
          {
            field: "rule_number",
            headerName: "Rule #",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "text",
            required: true,
          },
          {
            field: "rule_level",
            headerName: "Rule Level",
            filter: "agTextColumnFilter",
            width: column_width,
            resizable: true,
            formType: "text",
            required: false,
          },
        ],
      },
    ];
  }
}


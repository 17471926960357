import React from "react";
import Select from "../Select";
import { Box } from "@mui/material";
//static rule names
const options = [
  "Headend - Hardware No Response",
  "Headend - Degraded Laser Power",
  "Headend - Link break",
  "Headend - Software application watchdog",
  "Maint - EnableFrontRJ45",
  "Maint - CreateOffload",
  "Maint - ExConnSetWapRadioStatus",
  "Maint - StartBiteTest",
  "Maint - ResetSeatByRange",
  "QT CM GUI - Interactive Seat Reset",
  "QT CM GUI - Internet Access Control",
  "QT CM GUI - PED Power Control",
  "QT CM GUI - USB Power Control",
  "QT CM GUI - Full Seat Reset",
  "One Media - Missed Opportunity Spike",
  "One Media - Missed Opportunity for Invalid Zones",
  "One Media - Missed Opportunity for Invalid Sizes",
  "One Media - Missed Impression for active campaigns",
  "Headend - Hardware No Response+Maint - ResetSeatByRange",
  "Headend - Degraded Laser Power+QT CM GUI - USB Power Control",
  "Maint - ResetSeatByRange+QT CM GUI - Interactive Seat Reset",
  "Headend - Link break+QT CM GUI - Internet Access Control",
  "Headend - Hardware No Response+QT CM GUI - Full Seat Reset",
];

export default function RuleName(props) {
  return (
    <Box sx={{ paddingTop: "10px" }}>
      <Select
        key="ruleName"
        id="ruleName"
        name="ruleName"
        field="rule_name"
        label="Rule Name"
        variant="standard"
        defaultValue={props.defaultValue}
        data={props.data}
        required={props.required}
        helperText={props.helperText}
        queryStringParameters={{
          operator_icao_code: props.operator_icao_code,
          live_only: true,
        }}
        handleChange={props.handleChange}
        error={props.error}
        options={options}
        //   getOptionLabel={optionLabel}
        //   renderOption={renderOption}
        noOptionsText={
          props.operator_icao_code
            ? "No ruleName found"
            : "Please select an Rule Name"
        }
      />
    </Box>
  );
}


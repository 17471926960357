import React, { useState, useEffect }  from 'react';
//import ReactDOMServer       from 'react-dom/server';
import { Logger }           from 'aws-amplify';
import { withStyles }       from '@material-ui/core/styles';
import { useTheme }         from '@material-ui/core/styles';
import useMediaQuery        from '@material-ui/core/useMediaQuery';

import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogTitle          from '@material-ui/core/DialogTitle';
import TextField            from '@material-ui/core/TextField';
//import FormControlLabel     from '@material-ui/core/FormControlLabel';
//import Switch               from '@material-ui/core/Switch';
import Typography           from '@material-ui/core/Typography';
import Accordion            from '@material-ui/core/Accordion';
import MuiAccordionSummary  from '@material-ui/core/AccordionSummary';
import AccordionDetails     from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore';
//import Tooltip              from '@material-ui/core/Tooltip';

import MultiSelect          from './FormElements/MultiSelect';
import Select               from './FormElements/Select';
import BooleanElement       from './FormElements/Checkbox';
import QueryBuilder         from './FormElements/QueryBuilder';

import Icon                 from 'components/Icon';

//import OperatorService from "../services/OperatorService";
//import { getIcon } from "../data/Operators";

const logger = new Logger('UploadDialog');



export default function FormDialog(props) {
    logger.debug("props", props);

    const [selectedFile, setSelectedFile] = useState({});
    const [output, setOutput] = useState(props.output);

    var handleClose = () => {
        props.handleClose();
    };
    
    const handleFileUpload = (event) => {
        const max_fileSize = 10;
        logger.debug('handleFileUpload', event);
        logger.debug('handleFileUpload', event.target);
        setSelectedFile(event.target.files[0]);
        const fileSizeInMB = (event.target.files[0].size / (1024 * 1024)).toFixed(2);
        
       
        if (fileSizeInMB <= max_fileSize) {
            logger.debug('handleFileUpload filesize ok', event.target.files[0].size, fileSizeInMB);
            var reader = new FileReader();
            reader.onload = function(e) {
                var contents = e.target.result;
                logger.debug("contents", contents);
                setOutput(props.uploadData(contents));
            };
            reader.readAsText(event.target.files[0]);
        }
        else {
            logger.debug('handleFileUpload filesize bad', event.target.files[0].size, fileSizeInMB);
            setOutput("File is too large at " + fileSizeInMB + " MB.  File must be less than " + max_fileSize + " MB.");
        }
        logger.debug('handleFileUpload', event.target.files);
        logger.debug('handleFileUpload', event.target.files[0]);
    };
    

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} >
            <DialogTitle>Upload Data</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Select File
                        <input
                            type="file"
                            hidden
                            accept=".txt,.json,.sql,.csv"
                            onChange={handleFileUpload}
                        />
                    </Button>
                    <span style={{paddingLeft: "16px"}}>{selectedFile.name}</span>
                </DialogContentText>   
                <DialogContentText>
                    <textarea rows="16" disabled  style={{width: "100%", resize: "none"}} defaultValue={output}></textarea>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Operational view for viewing and editing the reports system and menu
 */
import React, { useContext } from 'react';
import { Logger }   from 'aws-amplify';
import View         from 'pac-responsive-ui-framework/core/View';
import Typography   from '@material-ui/core/Typography';
import AGGrid       from '../components/AGGrid';
import Service      from '../services/SLAExclusionParamService';
import AppContext   from 'pac-responsive-ui-framework/core/AppContext';

const logger = new Logger('OpsExclusionParams');

export default function OpsExclusionParams(props) {
    
    logger.debug(props);
    var app_context = useContext(AppContext);
    
    const service = new Service(app_context.state);
    
    var header =  <Typography variant="h5">{props.title}</Typography>;
    
    return (
        <View title={props.title} fixedContent={true}>
           <AGGrid service={service} recordID={props.recordID} columnDefs={service.getColumnDefs()} filename="exclusion_params_list" header={header} />
       </View>
    );
}


export default function PagerDutyCellRenderer() {
}

PagerDutyCellRenderer.prototype.init = function (params) {
    console.log('URLCellRenderer.prototype.init', params);
   
    if (params.value) {
        var span = document.createElement('span');
        var a = document.createElement('a');
        a.innerText  = "PD:" + params.value;
        a.target = "_blank";
        a.href = "https://panasonic.pagerduty.com/incidents/" + params.value;
        span.appendChild(a);
        
        this.eGui = span;
    }
    else this.eGui ="";
};

PagerDutyCellRenderer.prototype.getGui = function () {
    return this.eGui;
};


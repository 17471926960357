/**
 * This module contains the data as well as abstracted calls to retreive the data.
 * 
 * Eventually the data will be retreived from a data source.
 * 
 */
import _ from 'lodash';
import deburr from 'lodash/deburr';
import { Logger } from 'aws-amplify';
import TokenHelper from '../config/TokenHelper';
import { constants } from 'assets/Config.js';

const logger = new Logger('ReportList');



if (window.location.origin.indexOf("amazonaws") > -1) {
    // we are in Cloud9 so override server to hit the dev CloudFront 
    var kibana_server = "https://origins-dev.nextcloud.aero";
    //var api_gateway_server = "https://api.origins-dev.nextcloud.aero"
    var api_gateway_server = "https://ut6i88bdp5.execute-api.us-west-2.amazonaws.com/dev";
    //const php_html_root = "https://ea931ad80de546668793c48b0380317c.vfs.cloud9.us-west-2.amazonaws.com:8081/";
    var php_html_root = "https://php.origins-dev.nextcloud.aero/";
}
else {
    var kibana_server = window.location.origin;
    // since we are only loading into production ES - within the TEST env - point to production ES cluster (Samir Demo 05082020)
    if (process.env.REACT_APP_STAGE == 'test') {
        kibana_server = "https://origins.nextcloud.aero";
    }

    //var api_gateway_server = "https://api."+window.location.host;
    var api_gateway_server = "https://api.origins-dev.nextcloud.aero"

    if (process.env.REACT_APP_STAGE == 'dev' || process.env.REACT_APP_STAGE == 'test') {
        var php_html_root = "https://php.origins-dev.nextcloud.aero/";
    }
    else {
        var php_html_root = "https://php.origins.nextcloud.aero/";
    }
}

/**
 * General sort function which sorts the objects of an array based on an object attribute.
 * 
 * list is a reference array of objects.
 * key is the key in the object to sort by
 */
function sortObject(list, key) {
    list.sort(function(a, b) {
        if (typeof a[key] === 'number')
            return a[key] - b[key];
        else
            return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
    })
}

/*
var category_list = [
    { order: 5, title: 'Business Operations' },
    { order: 1, title: 'Connectivity Analytics' },
    { order: 2, title: 'Connectivity Logs' },
    { order: 3, title: 'Digital Solutions' },
    { order: 4, title: 'InFlight Systems' },

];
sortObject(category_list, 'order');
//logger.debug(category_list);
*/

//var report_list = require('../data/' + constants.report_list);

/**
 * Sets the list of favorite report
 * 
 * used in the component NavigationMenu and the Search view and is set from the app_context object.
 */
var report_list;
export function setReportList(value) {
    report_list = [];
    const tokenHelper = new TokenHelper();
    const id_token = tokenHelper.getIdToken(true);
    var access_token;
    try {
        access_token = tokenHelper.getAccessTokenFromStore();
    }
    catch (error) {
        access_token = '';
    }
    //logger.debug("id_token", id_token, access_token);


    var temp = value.config;
    
    //logger.debug("JSON CODE1", temp, report_list);
    for (var i = 0; i < temp.length; i++) {
        if (temp[i].enabled) {
            
            // href is the path within the website
            //logger.debug("setReportList", temp[i]);
            if (!temp[i].href) temp[i].href = "/dashboards/{title}";
            temp[i].href = temp[i].href.replace('{title}', temp[i].title);
            // report_href is the url for the embedded report
            if (temp[i].report_href && typeof temp[i].report_href === 'string') {
                temp[i].report_href = temp[i].report_href.replace('{api_gateway_server}', api_gateway_server);
                temp[i].report_href = temp[i].report_href.replace('{kibana_server}', kibana_server);
                temp[i].report_href = temp[i].report_href.replace('id_token={id_token}', 'id_token={id_token}&access_token={access_token}');
                temp[i].report_href = temp[i].report_href.replace('{id_token}', id_token);
                temp[i].report_href = temp[i].report_href.replace('{access_token}', access_token);
                
            }
            
           
            report_list.push(temp[i]);
        }
    }

    //logger.debug("JSON CODE2", report_list);
    sortObject(report_list, 'title');
}

var favorite_reports = [];

/**
 * Sets the list of favorite report
 * 
 * used in the component NavigationMenu and the Search view and is set from the app_context object.
 */
export function setFavoriteReports(value) {
    favorite_reports = value;
}

function add_last_visit_time(report_details, state) {
    if (_.get(state, 'user.session.recent_reports', false)) {
        var recent_reports = state.user.session.recent_reports;
        logger.debug("add_last_visit_time reports", report_details.title, state.user.session.recent_reports);
    
        for (var i = 0; i < recent_reports.length; i++) {
            logger.debug("add_last_visit_time reports2",  Date.parse( report_details['last_visit_time']), recent_reports[i]['time'] );
        
            if (recent_reports[i]['report'] === report_details['title'] && (
                    !report_details['last_visit_time'] || Date.parse( report_details['last_visit_time']) < recent_reports[i]['time'] 
                    )) { 
                var date = new Date(recent_reports[i]['time']);
                report_details['last_visit_time'] =  date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2)+ '-' + date.getDate() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':'+ date.getSeconds().toString().padStart(2, '0'); 
            }
        }
    }
    
    if (_.get(state, 'user.session.recent_visits', false)) {
                logger.debug("add_last_visit_time visits", report_details.title, state.user.session.recent_visits);

        for (const property in state.user.session.recent_visits) {
    
            //logger.debug("add_last_visit_time getRecentReportList report_details", property , report_details['title']);
            if (property === report_details['title'] && 
                (!report_details['last_visit_time'] || Date.parse( report_details['last_visit_time']) <  Date.parse(state.user.session.recent_visits[property]['SK'].substring(0, 19).replace("T", " ")))) { 
                report_details['last_visit_time'] = state.user.session.recent_visits[property]['SK'].substring(0, 19).replace("T", " ");
            }
        }
    }
    return report_details;
}



export function getReportList(exclusion_list = [], state) {
    //logger.debug("getReportList state", exclusion_list, state);
    if (!state.user) return [];
    var acl_roles = state.user.session.acl_role;
    var acl_icaos = state.user.session.pac_icao_code
    var selected_icao = state.default_operator_icao_code;

    //logger.debug("getReportList exclusion_list", report_list, exclusion_list, favorite_reports, acl_roles, acl_icaos, selected_icao);
    var new_report_list = [];
    for (var i = 0; i < report_list.length; i++) {
        // check the report and user roles
        var allowed = is_allowed(report_list[i], acl_roles, acl_icaos, selected_icao, process.env.REACT_APP_STAGE);
        if (allowed) {
            if (inReportList(report_list[i].title, exclusion_list) === -1) {
                //Check if report is restricted to particular environment
                //console.log(report_list[i].report_environments, process.env.REACT_APP_STAGE);
                if (Array.isArray(report_list[i].report_environments) && report_list[i].report_environments.includes(process.env.REACT_APP_STAGE) === false) {
                    continue;
                }
                if (favorite_reports.indexOf(report_list[i].title) == -1)
                    report_list[i].favorite = false;
                else
                    report_list[i].favorite = true;
                
                report_list[i] = add_last_visit_time(report_list[i], state);
                new_report_list.push(report_list[i]);
            }
        }
    }
    
    new_report_list.sort(function(a, b) {
        if (a.favorite == b.favorite) {
            return a['title'].toLowerCase().localeCompare(b['title'].toLowerCase());
        }
        //    else if (a.favorite) return -1; //sort favorites at the top
        else return 1;
    });
    return new_report_list;
}

/**
 * needle   string  text you are searching for
 * haystack array   array of objexts that must have title defined
 * 
 * returns index of the matching report | -1
 */
function inReportList(title, tmp_report_list) {
    //logger.debug('inReportList init', title, tmp_report_list);
    for (var i = 0; i < tmp_report_list.length; i++) {
        //logger.debug('inReportList compare', tmp_report_list[i].title, title);
        if (tmp_report_list[i].title == title) {
            //logger.debug('inReportList return', i);
            return i;
        }
    }
    //logger.debug('inReportList return', -1)
    return -1;
}

/**
 * needle   string  text you are searching for
 * haystack array   array of test strings to match to
 */
function in_array(needle, haystack) {
    for (var i = 0, len = haystack.length; i < len; i++) {
        if (haystack[i] == needle) return i;
    }
    return -1;
}




/**
 * Returns an array of report details data. 
 * Requires a app_context to get the listof recent reports
 * User context may be replaced with an API call at some point
 */
export function getRecentReportList(app_context) {
    logger.debug("getRecentReportList app_context", app_context);
    var recent_report_count = 12;
    var new_report_list = [];
    var tmp_report_list = [];
    var tmp_report_times_list = [];


    if (_.get(app_context, 'state.user.session.recent_reports', false)) {
        var recent_reports = app_context.state.user.session.recent_reports;
        logger.debug("getRecentReportList recent_reports", recent_reports);

        
        for (var i = 0; i < recent_reports.length; i++) {
            if (in_array(recent_reports[i]['report'], tmp_report_list) === -1 
                    && in_array(recent_reports[i]['report'], favorite_reports) === -1 ) {
                tmp_report_list.push(recent_reports[i]['report']);
                tmp_report_times_list.push(recent_reports[i]['time']);
            }
            if (tmp_report_list.length == recent_report_count) break;
        }
        for (var i = 0; i < tmp_report_list.length; i++) {
            var report_details = getReportDetails(tmp_report_list[i], 
                    app_context.state.user.session.acl_role, 
                    app_context.state.user.session.pac_icao_code,
                    app_context.state.default_operator_icao_code);
            
                    
            //Check if report is restricted to particular environment
            if (Array.isArray(report_details.report_environments) && report_details.report_environments.includes(process.env.REACT_APP_STAGE) === false) {
                continue;
            }
            
            
            if (report_details) {
                report_details =  add_last_visit_time(report_details, app_context.state);
                new_report_list.push(report_details);
            }
        }
    }

    const new_report_list_count = new_report_list.length;
    if (new_report_list_count < recent_report_count) {
        var recent_visits = _.get(app_context, 'state.user.session.recent_visits', {});
        logger.debug("getRecentReportList recent_visits", recent_visits);


        Object.keys(recent_visits).forEach(function(key, index) {
            logger.debug('getRecentReportList key, index', key, index, in_array(key, favorite_reports));

            if (in_array(key, tmp_report_list) === -1 && in_array(key, favorite_reports) === -1 ) {
                if (new_report_list.length < recent_report_count) {
                    var report_details = getReportDetails(key, 
                            app_context.state.user.session.acl_role, 
                            app_context.state.user.session.pac_icao_code,
                            app_context.state.default_operator_icao_code);
                    if (report_details) {
                        report_details = add_last_visit_time(report_details, app_context.state);    
                          
                        //Check if report is restricted to particular environment
                        if (Array.isArray(report_details.report_environments)) {
                            if (report_details.report_environments.includes(process.env.REACT_APP_STAGE)) {
                                new_report_list.push(report_details);
                            }

                        }
                        else {
                            new_report_list.push(report_details);
                        }
                    }


                }
            }
        });
    }
    logger.debug("getRecentReportList new_report_list", new_report_list);
    return new_report_list;
}

/**
 * environment = dev | test | prd
 */
function is_allowed(report, acl_roles, acl_icaos, selected_icao, environment) {
    var allowed = false;
    if (report) {
        logger.debug("is_allowed", report.title);
        logger.debug("is_allowed2", report.title, report, acl_roles, acl_icaos, selected_icao, environment);
        allowed = true;
         if (environment === 'dev') {
            if (report.report_type === "quicksight" && (report.dashboard_id_dev === undefined || report.dashboard_id_dev === "")) allowed = false;
            if (!report.publish_dev) allowed = false;
        }
        else if (environment === 'test') {
            if (report.report_type === "quicksight" && (report.dashboard_id_test === undefined || report.dashboard_id_test === "")) allowed = false;
            if (!report.publish_test) allowed = false;
        }
        else if (environment === 'prd') {
            if (report.report_type === "quicksight" && (report.dashboard_id === undefined || report.dashboard_id === "")) allowed = false;
            if (!report.publish_prod) allowed = false;
        }
        
        logger.debug("is_allowed3", report.report_type, report.dashboard_id_dev, allowed);
        if (allowed) {
    
            if (constants.overwrite_roles_by_icao) {
                //logger.debug("is_allowed1", report.title, acl_roles, acl_icaos, selected_icao);
                if (selected_icao == "---") selected_icao = null;
                if (selected_icao) {
                    acl_roles = [];
                    acl_icaos = selected_icao;
                    //logger.debug("is_allowed2", acl_roles, acl_icaos);
                }
            }
            //logger.debug("is_allowed3", acl_roles, acl_icaos, selected_icao);
    
            if ('assert_role' in report && report.assert_role) {
                //logger.debug("is_allowed4a assert_role", report.title, report.assert_role, acl_roles, acl_icaos);
                allowed = false;
                for (var j = 0; j < report.assert_role.length; j++) {
                    //logger.debug("is_allowed acl_roles found", report.title, report.assert_role[j]);
                    if (Array.isArray(acl_roles)) {
                        acl_roles.forEach((role) => {
                            if(role.startsWith(report.assert_role[j])) {
                                allowed = true;
                            }
                        });
                        //logger.debug("is_allowed4b allowed");
                        
                    }
                }
                //logger.debug("is_allowed4c", allowed);
            }
            
            if ('assert_icao' in report && report.assert_icao && acl_icaos && allowed === true) {
                //logger.debug("is_allowed5a", report.assert_icao, acl_icaos);
                //logger.debug("assert_icao");
                allowed = false;
                for (var j = 0; j < report.assert_icao.length; j++) {
                    //logger.debug("is_allowed5b acl_icaos found", report.title, acl_icaos, report.assert_icao[j]);
                    if (acl_icaos.toLowerCase() == report.assert_icao[j].toLowerCase() || acl_icaos == 'pac') {
                        //logger.debug("is_allowed allowed5c", report.title, report.assert_icao[j]);
                        allowed = true;
                    }
                }
                //logger.debug("is_allowed6", report.title, allowed);
            }
        }
    }
    //logger.debug("is_allowed7", allowed);
    return allowed;
}

export function getReportDetails(reportId, acl_roles, acl_icaos, selected_icao) {
    var report = report_list.find(({ title }) => title === reportId);
    logger.debug("getReportDetails", report, reportId, report_list);
    var allowed = is_allowed(report, acl_roles, acl_icaos, selected_icao);
    if (!allowed) return false;
    if (report) {
        if (favorite_reports.indexOf(reportId) == -1)
            report.favorite = false;
        else
            report.favorite = true;
    }
    
    return report;
}

export function getQuicksightEmbedURL() {
    return api_gateway_server + "/getembedurl/";
}

export function searchReports(search, limit = 20, state) {
    search = deburr(search.trim()).toLowerCase();
    let count = 0;
    let results = [];
    var report_list = getReportList([], state);
    results = report_list.filter(suggestion => {
        //logger.debug("searchReports", suggestion);
        if (search.indexOf('env:') !== -1) {
            var report_environments = suggestion.report_environments;
            if (report_environments == null || report_environments == "") {
                report_environments = ['prod'];
            }

            report_environments.filter(env => {
                if (env == 'dev' && report_environments.includes('test')) { keep = false; }
                else if (env.toLowerCase().indexOf(search.split('env:')[1]) !== -1) {
                    keep = true;
                }
            });
        }
        else {

            var keep =
                //count < 10 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
                count < limit && suggestion.title.toLowerCase().indexOf(search) !== -1;

            if (keep) {
                count += 1;
            }
            else if (count < limit && suggestion.tags) {
                suggestion.tags.filter(tag => {
                    if (tag.toLowerCase().indexOf(search) !== -1) {
                        keep = true;
                    }
                });
            }


        }
        return keep;
    });

    var results1 = [];
    var results2 = [];
    for (var i = 0; i < results.length; i++) {
        if (results[i].title.toLowerCase().indexOf(search) !== -1) {
            results1.push(results[i]);
        }
        else {
            results2.push(results[i]);
        }
    }

    //results = results1 + results2;

    //logger.debug("searchReports", search, results1, results2, results);

    return results1.concat(results2);
}



/*
export function getCategories() {
    var categories = {};
    category_list.forEach(category => {
        categories[category['title']] = []
    })
    return categories;
}
*/

/**
 * This returns a string in the format "?operator=<op>&start=<start>&end=<end>"
 * assuming the values are set in the working menu bar.
 * This is used for external links that need to pass along the working values.
 * If set in the URL, then it sets those in a newly opened window.
 */
export function getURLParams(app_context) {
    var url_parameters = [];
    if (typeof app_context.state.default_operator_icao_code !== 'undefined') {
        var default_operator_icao_code = app_context.state.default_operator_icao_code;
    if(typeof default_operator_icao_code !== 'undefined' && default_operator_icao_code != '---') {
        url_parameters.push("operator=" + default_operator_icao_code);
    }
    var start_date = app_context.state.start_date;
    var end_date = app_context.state.end_date;
    if (start_date && end_date) {
        url_parameters.push("start=" + start_date);
        url_parameters.push("end=" + end_date);
    }
    logger.debug("url_parameters", url_parameters);
    if (url_parameters.length) {
        url_parameters = "?" + url_parameters.join("&");
    }
    else url_parameters = "";
        logger.debug("url_parameters", url_parameters);
    }
    return url_parameters;
}

export default {
    getReportList,
    getRecentReportList,
    getReportDetails,
    searchReports,
    setFavoriteReports,
  //  getCategories,
    getURLParams
};


const string_operators = [
    { name: "equal",            label: "=" },
    { name: "not_equal",        label: "!=" },
    { name: "contains",         label: "contains" },
    { name: "not_contains",     label: "not contains" },
    { name: "in",               label: "in" },
    { name: "not_in",           label: "not in" },
    { name: "like",             label: "like" },
    { name: "not_like",         label: "not like" },
    { name: "begins_with",      label: "begins with" },
    { name: "ends_with",        label: "ends with" },
    { name: "not_begins_with",  label: "does not begin with" },
    { name: "not_ends_with",    label: "does not end with" },
    { name: "is_empty",         label: "is emplty" },
    { name: "is_not_empty",     label: "is not empty" },
    { name: "is_null",          label: "is null" },
    { name: "is_not_null",      label: "is not null" }

];

export default [
  
 /*
  { source: "dl_pac_origins_aircraft_v1.logs", label: "Flight Event Logs", columns: 
    [
      { name: "icao", label: "A/C Logs Operator ICAO Code", description: ""},
      { name: "registration_name", label: "A/C Logs Registration Name", description: ""},
      { name: "source", label: "Source" },
      { name: "key", label: "Key" },
      { name: "value", label: "Value" },
    ]
  },
  */
  { source: "pac_dl_raw_db.tflight_events", label: "Flight Event Logs", data_set: "FLIGHT_EVENTS", columns: 
    [
      { name: "operator", label: "Airline Operator", description: ""},
      { name: "tail", label: "Tail code", description: ""},
      { name: "system", label: "System Name" },
      { name: "log_key", label: "Key", description: "The column identifies the application e,g. Maint, CMI GUI etc." },
      { name: "log_value", label: "Log Value", description: "The column contains the log text" },
    ]
  },
  /*
  { source: "dl_pac_origins_pacwisplive_v1.wisp_session_connect", label: "WISP Session", columns: [
     { name: "eventtime", label: "WISP Session Event Time", description: ""},
     { name: "icao", label: "WISP Session Operator ICAO Code", description: ""},
     { name: "correlationdata.sessionid", label: "WISP Correlation Session ID", description: ""},
     { name: "data.clientinformation.username", label: "WISP Session Username", description: ""},
     { name: "data.clientinformation.emailaddress", label: "WISP Session Email Address", description: ""},
     { name: "data.clientinformation.accountid", label: "WISP Session Account ID", description: ""},
     { name: "data.subscribername", label: "WISP Session Subscriber Name", description: ""},
     { name: "data.transactioninformation.transactionid", label: "WISP Session Transaction ID", description: ""},	
     { name: "data.transactioninformation.transactiontime", label: "WISP Session Transaction Time", description: ""},
     { name: "data.transactioninformation.voucherbatch", label: "WISP Session Voucher Batch", description: ""},	
     { name: "data.transactioninformation.transactionprice", label: "WISP Session Transation Price", description: ""},	
     { name: "data.deviceinformation.ip", label: "WISP Session Device IP", description: ""},	
     { name: "data.deviceinformation.mac", label: "WISP Session Device MAC", description: ""},	
     { name: "data.captchapassed", label: "WISP Session Captcha Passed", description: ""},	
     { name: "data.sessionid", label: "WISP Session ID", description: ""},	
     { name: "data.icao", label: "WISP Session ICAO ", description: ""},
     { name: "data.planinformation.planapplications", label: "WISP Session Plan Applications", description: ""},	
     { name: "data.planinformation.plandevicetype", label: "WISP Sessio Plan Device type", description: ""},	
     { name: "data.planinformation.planvouchertype", label: "WISP Session Plan Voucher Type", description: ""},	
     { name: "data.planinformation.plancurrency", label: "WISP Session Plan Currency", description: ""},	
     { name: "data.planinformation.planid", label: "WISP Session Plan ID", description: ""},	
     { name: "data.planinformation.planroamingpartner", label: "WISP Session Plan Roaming Partner", description: ""},	
     { name: "data.planinformation.planpaymentvendorname", label: "WISP Session Plan Payment Vendor", description: ""},	
     { name: "data.planinformation.plantype", label: "WISP Session Plan Type", description: ""},	
     { name: "data.planinformation.plansubscription", label: "WISP Session Plan Subscription", description: ""},	
     { name: "data.planinformation.plantiername", label: "WISP Session Plan Tier Name", description: ""},	
     { name: "data.planinformation.plantimelimit", label: "WISP Session Plan Time Limit", description: ""},	
     { name: "data.planinformation.planpayment", label: "WISP Session Plan Payment", description: ""},	
     { name: "data.planinformation.plantier", label: "WISP Session Plam Tier", description: ""},	
     { name: "data.planinformation.planbytelimit", label: "WISP Session Plan Byte Limit", description: ""},	
     { name: "data.flightinformation.associated_arrive_airport_icao", label: "WISP Session Arrive Airport", description: ""},	
     { name: "data.flightinformation.departuretime", label: "WISP Session Departure Time", description: ""},	
     { name: "data.flightinformation.associated_arrive_airport_iata", label: "WISP Session Arrival IATA", description: ""},	
     { name: "data.flightinformation.associated_takeoff_time_utc", label: "WISP Session Takeoff Time (UTC)", description: ""},	
     { name: "data.flightinformation.associated_flight_excluded", label: "WISP Session Flight Excluded", description: ""},	
     { name: "data.flightinformation.associated_flight_inop", label: "WISP Session Flight INOP", description: ""},	
     { name: "data.flightinformation.destinationcity", label: "WISP Session Destination City", description: ""},	
     { name: "data.flightinformation.associated_depart_airport_icao", label: "WISP Session Departure ICAO", description: ""},	
     { name: "data.flightinformation.flightnumber", label: "WISP Session Flight Number", description: ""},	
     { name: "data.flightinformation.associated_depart_gate_time_utc", label: "WISP Session Depart Gate UTC", description: ""},	
     { name: "data.flightinformation.associated_flight_number", label: "WISP Session Associated Flight Number", description: ""},	
     { name: "data.flightinformation.associated_flight_id", label: "WISP Session Flight ID", description: ""},	
     { name: "data.flightinformation.associated_landing_time_utc", label: "WISP Session Landing Time (UTC)", description: ""},	
     { name: "data.flightinformation.associated_depart_airport_iata", label: "WISP Session Associated Departure IATA", description: ""},	
     { name: "data.flightinformation.associated_arrive_gate_time_utc", label: "WISP Session Associated Arrival Gate Time (UTC)", description: ""},	
     { name: "data.flightinformation.origincity", label: "WISP Session Origin City", description: ""},	
     { name: "data.flightinformation.tailnumber", label: "WISP Session Registration Name", description: ""},	
     { name: "data.all.device.mac", label: "WISP Session MAC Address", description: ""},	
     { name: "data.all.device.user_agent", label: "WISP Session User Agent", description: ""},	
     { name: "data.all.device.device_type", label: "WISP Session Device Type", description: ""},	
     { name: "data.all.wisp.connection_url", label: "WISP Session Connection URL", description: ""},	
     { name: "data.all.wisp.app", label: "WISP Session App", description: ""},	
     { name: "data.all.wisp.acct_username", label: "WISP Session account username", description: ""},	
     { name: "data.all.wisp.acct_id", label: "WISP Session Account ID", description: ""},	
     { name: "data.all.wisp.captcha_passed", label: "WISP Session Captcha Passed", description: ""},	
     { name: "data.all.wisp.acct_email", label: "WISP Session Account Email", description: ""},	
     { name: "data.all.connection.username", label: "WISP Connection Username", description: ""},	
     { name: "data.all.connection.calledstationid", label: "WISP Connection Called Station ID ", description: ""},	
     { name: "data.all.connection.callingstationid", label: "WISP Connection Calling Station ID", description: ""},	
     { name: "data.all.connection.realm", label: "WISP Connection Realm ", description: ""},	
     { name: "data.all.connection.framedipaddress", label: "WISP Connection Framed IP Address", description: ""},	
     { name: "data.all.connection.acctstarttime", label: "WISP Connection Account Start Time", description: ""},	
     { name: "data.all.connection.nasipaddress", label: "WISP Conneciton NAS IP Address", description: ""},	
     { name: "data.all.connection.acctsessionid", label: "WISP Connection Account Session ID", description: ""},	
     { name: "data.all.connection.nasporttype", label: "WISP Connection NAS Port Type", description: ""},	
     { name: "data.all.connection.acctstatustype", label: "WISP Connection Account Status Type", description: ""},	
     { name: "data.all.connection.acctuniqueid", label: "WISP Connection Account Unique ID", description: ""},	
     { name: "data.all.flight.dep_time", label: "WISP Flight Departure Time", description: ""},	
     { name: "data.all.flight.org_iata", label: "WISP Flight Departure IATA", description: ""},	
     { name: "data.all.flight.dest_iata", label: "WISP Flight Arrival IATA", description: ""},	
     { name: "data.all.flight.dest_icao", label: "WISP Flight Arrival ICAO ", description: ""},	
     { name: "data.all.flight.tailno", label: "WISP Flight Registration Name ", description: ""},	
     { name: "data.all.flight.arr_time", label: "WISP Flight Arrival Time ", description: ""},	
     { name: "data.all.flight.org_icao", label: "WISP Flight Departure ICAO", description: ""},	
     { name: "data.all.flight.airline_icao", label: "WISP Flight Operator ICAO Code", description: ""},	
     { name: "data.all.flight.flightno", label: "WISP Flight Number ", description: ""},	
     { name: "data.all.plan.plan_data", label: "WISP Plan Data ", description: ""},	
     { name: "data.all.plan.tsp", label: "WISP Plan TSP ", description: ""},	
     { name: "data.transactioninformation.cardtype", label: "WISP Transation Card Type", description: ""},	
     { name: "data.deviceinformation.useragent", label: "WISP Device User Agent", description: ""},	
     { name: "data.planinformation.planname", label: "WISP Plan Name ", description: ""},	
     { name: "data.planinformation.plansubscribername", label: "WISP Plan Subscriber Name ", description: ""},	
     { name: "data.rasconnectionid", label: "WISP RAS Connection ID ", description: ""},
     { name: "data.event", label: "WISP Event", description: ""},	
     { name: "data.flightinformation.associated_tailno", label: "WISP Flight Associated Tail Number ", description: ""},	
     { name: "data.transactioninformation.paymenttype", label: "WISP Transation Payment Type", description: ""},	
     { name: "data.flightinformation.associated_icao", label: "WISP Flight Associated ICAO Code", description: ""},	
     { name: "data.deviceinformation.countryofresidence", label: "WISP Device Country of Residence", description: ""},	
     { name: "data.planinformation.planprice", label: "WISP Plan Price", description: ""},	
     { name: "data.transactioninformation.pnr", label: "WISP Transation PNR", description: ""},	
     { name: "data.dotransactioninformation.isguest", label: "WISP Transation Is Guest ", description: ""},	
     { name: "dt",label: "WISP Session dt", description: ""}, 
     { name: "eventtime - data.flightinformation.departuretime",label: "WISP Event Delta", description: ""}, 
     
    ]
  },
  { source: "dl_pac_dp_fds_raw_prod_v1.mst_fds_flight_events", label: "FDS Flight Events", columns: [
      { name: "operatoricaocode", label: "FDS Operator ICAO Code", descrption: "" },
      { name: "aircraftregistrationnumber", label: "FDS Registration Name", descrption: "" },
      { name: "flightnumber", label: "FDS Flight Number", descrption: "" },
      { name: "airportiatadeparturecode", label: "FDS IATA Departure Code", descrption: "" },
      { name: "airportiatadeparturecode", label: "FDS IATA Departure Code", descrption: "" },
      { name: "airporticaodeparturecode", label: "FDS ICAO Departure Code", descrption: "" },
      { name: "airportiataarrivalcode", label: "FDS IATA Arrival Code", descrption: "" },
      { name: "airporticaoarrivalcode", label: "FDS ICAO Arrival Code", descrption: "" },
      { name: "flightroutecode", label: "FDS Flight Route Code", descrption: "" },
      { name: "departgatedate", label: "FDS Departure Gate Time", descrption: "" },
      { name: "takeoffdate", label: "FDS Takeoff Time", descrption: "" },
      { name: "landingdate", label: "FDS Landing Time", descrption: "" },
      { name: "arrivegatedate", label: "FDS Arrival Gate Time", descrption: "" },
      { name: "estimatedlandingdate", label: "FDS Estimated Landing Time", descrption: "" },
      { name: "flightstatecode", label: "FDS Flight Status", descrption: "" }
    ]
  },
  */
  { source: "tsyslog_bite", label: "BITE Events", data_set: "BITE", columns: 
    [
      { name: "flight_date", label: "BITE Flight Date", description: "The date that BITE flight data occurred.  Dates are based on takeoff time."},
      { name: "flight_number", label: "BITE Flight Number", description: "The flioght number associated with the flight. This usually cooresponds to the flight route and is not unique to the dat, or to the operator."},
    //  { name: "flight_number_raw", label: "BITE Raw Flight Number", description: "The flioght number associated with the flight. The raw format may include the operator ICAO Code."},
      { group: "Flight Details", name: "departure_city", label: "BITE Departure City", description: "" },
      { group: "Flight Details", name: "arrival_city", label: "BITE Arrival City", description: "" },
      { group: "Flight Details", name: "flight_duration", label: "BITE Flight Durration", description: "" },
      { name: "estimated_arrival", label: "BITE Estimated Arrival", description: "" },
      { name: "timestamp_ground", label: "BITE Ground Timestamp", description: "" },
      { name: "timestamp_departure", label: "BITE Departure Timestamp", description: "" },
      { name: "timestamp_arrival", label: "BITE Arrival Timestamp", description: "" },
      { group: "Aircraft Details", name: "system", label: "BITE Aircraft System", description: "" },
      { group: "Aircraft Details", name: "build", label: "BITE Aircraft Build", description: "" },
      { group: "Aircraft Details", name: "build_series", label: "BITE Aircraft Build Series", description: "" },
      { group: "Flight Details", name: "ground_date", label: "BITE Ground Data", description: "" },
    //  { name: "file_set_defected", label: "BITE Defect Fileset Flag", description: "" },
    //  { name: "file_set_defected", label: "BITE Defect Fileset Flag", description: "" },
    //  { name: "file_set_defects", label: "BITE Defect Reason", description: "" },
      { name: "operator", label: "BITE Operator ICAO Code", description: "" },
      { name: "tail", label: "BITE Aircraft Registration Name (Tail)", description: "" },
      { name: "fleet", label: "BITE Fleet Type", description: "" },
      { name: "activity_code", label: "BITE Event Active Flag", description: "" },
      { name: "address", label: "BITE Event Address", description: "" },
      { name: "bite_code", label: "BITE Event Bite Code", description: "" },
    //  { name: "detecting_address", label: "BITE Detecting Address", description: "" },
    //  { name: "detecting_lru_type", label: "BITE Detecting LRU Type", description: "" },
    //  { name: "detecting_lru_subtype", label: "BITE Detecting LRU Subtype", description: "" },
      { name: "duration", label: "BITE Flight Duration", description: "" },
    //  { name: "first_occurrence_timestamp", label: "BITE First Occurrence Timestamp", description: "" },
    //  { name: "last_occurrence_timestamp", label: "BITE Last Occurrence Timestamp", description: "" },
      { name: "lru_type", label: "BITE LRU Type", description: "" },
      { name: "lru_subtype", label: "BITE LRU Subtype", description: "" },
      { name: "occurrence_count", label: "BITE Ocurrence Count", description: "" },
      { name: "air", label: "BITE Airside Event Flag", description: "" },
    //  { name: "vendor", label: "BITE Vendor Event Flag", description: "" },
    //  { name: "preflight", label: "BITE Preflight Event Flag", description: "" },
    //  { name: "health", label: "BITE Health Event Flag", description: "" },
      { name: "lru_group", label: "BITE LRU Group", description: "" },
      { name: "bite_group", label: "BITE Group", description: ""},
      //{ name: "bite_group", label: "BITE Group", description: "",  
      //   operators: [
      //    { name: '=', label: '=' },
      //    { name: '!=', label: '!=' }
      // ],
      //valueEditorType: 'select', // see https://react-querybuilder.js.org/api/react-querybuilder/type-aliases/ValueEditorType
      //  values: [
      //    { name: '20', label: 'Reboot' },
      //    { name: '21', label: 'Command' },
      //    { name: '22', label: 'Restart' },
      //    { name: '24', label: 'Command Response' },
      //    { name: '120', label: 'Ground Reboot' },
      //    { name: '121', label: 'Ground Command' },
      //    { name: '122', label: 'Ground Restart' },
      //    { name: '124', label: 'Ground Command Response' }
      //  ], 
      //},
    //  { name: "bite_exclusion", label: "BITE Exclusion Flag", description: "" },
      { 
        name: "mmid", 
        label: "BITE MMID", 
        description: "",
        operators: string_operators
      },
    //  { name: "fault_abo_message", label: "BITE Fault ABO Message", description: "" },
    //  { name: "fault_ata_id", label: "BITE Fault ATA ID", description: "" },
      { name: "lruloc_lru_type", label: "BITE LRU Location Type", description: "" },
      { name: "lruloc_lru_subtype", label: "BITE LRU Location Subtype", description: "" },
      { name: "lruloc_address", label: "BITE LRU Location address", description: "" },
      { name: "lruloc_acronym", label: "BITE LRU Location Acronym", description: "" },
      { name: "lruloc_location", label: "BITE LRU Location", description: "" },
    //  { name: "lruloc_description", label: "BITE LRU Location Description", description: "" },
      { name: "detecting_lruloc_lru_type", label: "BITE LRU Location Type", description: "" },
      { name: "detecting_lruloc_lru_subtype", label: "BITE LRU Location Subtype", description: "" },
      { name: "detecting_lruloc_address", label: "BITE LRU Address", description: "" },
      { name: "detecting_lruloc_acronym", label: "BITE LRU Acronym", description: "" },
      { name: "detecting_lruloc_location", label: "BITE LRU Location", description: "" },
    //  { name: "detecting_lruloc_description", label: "BITE LRU Description", description: "" },
      { name: "fault_system_impact", label: "BITE Fault System Impact", description: "" },
      { name: "event", label: "BITE Event", description: "" },
      { name: "event_date", label: "BITE Event Date", description: "" },
    //  { name: "last_event_date", label: "BITE Last Event Date", description: "" },
      { name: "system_health", label: "BITE System Health Flag", description: "" },
      { name: "seat_health", label: "BITE Seat Health Flag", description: "" },
      { name: "reboot_reason", label: "BITE Reboot Reason", description: "" },
      { name: "hardware_part_number", label: "BITE Hardware Part Number", description: "" }
    ]
  },
  { source: "dl_pac_origins_wisp_portal_ui_v2.logs", label: "Wisp Portal", data_set: "WISP", columns: 
    [
      { name: "icao", label: "PORTAL Operator ICAO Code", description: "The operator ICAO Code associated with the event."},
      { name: "data.flightinformation.associated_icao", label: "(flightinfo) Operator ICAO Code", description: "The operator ICAO Code associated with the event."},
      { name: "data.all.flight.airline_icao", label: "(all flight) Operator ICAO Code", description: "The operator ICAO Code associated with the event."},

      { name: "data.flightinformation.tailnumber", label: "Flight Tail Number", description: "Registration name of the aircraft"},
      { name: "data.all.flight.tailno", label: "(all) Tail Number", description: ""},
      { name: "data.flightinformation.associated_tailno", label: "Associated Tail Number", description: ""},

      { name: "data.flightinformation.associated_flight_excluded", label: "Flight Excluded", description: ""},
      { name: "data.flightinformation.associated_flight_inop", label: "Flight InOp", description: ""},
      
      { name: "data.flightinformation.flightnumber", label: "Flight Number", description: ""},
      { name: "data.flightinformation.associated_flight_number", label: "Associated Flight Number", description: ""},
      { name: "data.all.flight.flightno", label: "Flight No", description: ""},
      
      //takeoff
      { name: "data.flightinformation.origincity", label: "Origin City", description: ""},
      { name: "data.all.flight.org_icao", label: "Origin City ICAO", description: ""},
      { name: "data.flightinformation.departuretime", label: "(flightinformation) Departure Time", description: ""},
      { name: "data.all.flight.dep_time", label: "(flight) Departure Time", description: ""},
      { name: "data.flightinformation.associated_takeoff_time_utc", label: "Associated Departure Time", description: ""},
      { name: "data.flightinformation.associated_depart_airport_iata", label: "Departure Airport IATA", description: ""},
      { name: "data.all.flight.org_iata", label: "Departure IATA", description: ""},
      { name: "data.flightinformation.associated_depart_airport_icao", label: "Departure Airport ICAO", description: ""},

      { name: "data.flightinformation.associated_depart_gate_time_utc", label: "Departure Gate Time", description: ""},
      
      // landing
      { name: "data.flightinformation.destinationcity", label: "Destination City", description: ""},
      { name: "data.flightinformation.associated_landing_time_utc", label: "Associated Landing Time", description: ""},
      { name: "data.all.flight.arr_time", label: "Arrival Time", description: ""},
      { name: "data.flightinformation.associated_arrive_gate_time_utc", label: "Arrival Gate Time", description: ""},
      { name: "data.all.flight.dest_iata", label: "Destination IATA", description: ""},
      { name: "data.all.flight.dest_icao", label: "Destination ICAO", description: ""},


      { name: "data.all.device.user_agent", label: "User Agent", description: ""},
      { name: "data.all.device.device_type", label: "Device Type", description: ""},
      { name: "data.all.wisp.connection_url", label: "Connection URL", description: ""},
      { name: "data.all.wisp.appl", label: "WISP Appl", description: ""},
      { name: "data.all.connection.calledstationid", label: "Called Station ID", description: ""},
      { name: "data.all.connection.callingstationid", label: "Calling Station ID", description: ""},
      { name: "data.all.connection.realm", label: "Realm", description: ""},
      { name: "data.all.connection.acctstarttime", label: "Account Start Time", description: ""},
      { name: "data.all.connection.nasporttype", label: "NAS Port Type", description: ""},
      { name: "data.all.plan.plan_data", label: "Plan Data", description: ""},
      { name: "data.all.plan.tsp", label: "Plan TSP", description: ""},

      { name: "data.transactioninformation.paymenttype", label: "Transation Payment Type", description: ""},
      { name: "data.transactioninformation.cardtype", label: "Transation Card Type", description: ""},
      
      { name: "data.planinformation.planname", label: "Plan Name", description: ""},
      { name: "data.planinformation.plansubscribername", label: "Plan Subscriber Name", description: ""},

      { name: "data.deviceinformation.useragent", label: "Device User Agent", description: ""},

      { name: "data.rasconnectionid", label: "RAS Connection ID", description: ""},

      { name: "data.event", label: "Event", description: ""},
      { name: "eventtime", label: "PORTAL Event Time", description: "The time that WISP event was recorded."},
      { name: "data.transactioninformation.transactiontime", label: "PORTAL Transation Time", description: "The transation time of the event."},
      { name: "data.transactioninformation.transactionprice", label: "Transation Price", description: "The transation price."},
      { name: "data.captchapassed", label: "Data CAPTCHA Passed", description: ""},
      { name: "data.all.wisp.captcha_passed", label: "WISP CAPTCHA Passed", description: ""},
      

      { name: "data.planinformation.planapplications", label: "Plan Applications", description: ""},
      { name: "data.planinformation.plandevicetype", label: "Plan Device Type", description: ""},
      { name: "data.planinformation.planvouchertype", label: "Plan Voucher Type", description: ""},
      { name: "data.planinformation.plancurrency", label: "Plan Currency", description: ""},
      { name: "data.planinformation.planroamingpartner", label: "Plan Roaming Partner", description: ""},
      { name: "data.planinformation.planpaymentvendorname", label: "Plan Payment Vendor Name", description: ""},
      { name: "data.planinformation.plantype", label: "Plan Type", description: ""},
      { name: "data.planinformation.plansubscription", label: "Plan Subscription", description: ""},
      { name: "data.planinformation.plantiername", label: "Plan Tier Name", description: ""},
      { name: "data.planinformation.plantimelimit", label: "Plan Time Limit", description: ""},
      { name: "data.planinformation.planpayment", label: "Plan Payment", description: ""},
      { name: "data.planinformation.plantier", label: "Plan Tier", description: ""},
      { name: "data.planinformation.planbytelimit", label: "Plan Byte Limit", description: ""},
      
      { name: "data.deviceinformation.countryofresidence", label: "Device Country of Residence", description: ""},
      
      { name: "data.planinformation.planprice", label: "Plan Price", description: ""},
      { name: "data.transactioninformation.pnr", label: "Transaction PNR", description: ""},
      { name: "data.transactioninformation.isguest", label: "Transaction Guest", description: ""}, // boolean
      { name: "data.all.plan.is_depleted", label: "Plan Is Depleted", description: ""},
      { name: "data.transactioninformation.vouchercode", label: "Transaction Voucher Code", description: ""},
    
      

    ]
  },
  { source: "dl_pac_dp_om_views_prod_v1.onemedia_missedoppor_all_airlines", label: "One Media", data_set: "ONE_MEDIA", columns: 
    [
      { name: "Airline", label: "Airline Operator", description: ""},
      { name: "Missed_Opportunity_Count", label: "Missed Opportunity Count", description: ""},
      { name: "Missed_Banner", label: "Missed Banner" },
      { name: "Missed_Video", label: "Missed Video", description: "Missed Video" },
      { name: "Missed_Interstitial", label: "Missed Interstitial", description: "Missed Interstitial" },
      { name: "Video_Impression", label: "Video Impression", description: "Video Impression" },
      { name: "Banner_Impression", label: "Banner Impression", description: "Missed Interstitial" },
      { name: "Missed_Percentage", label: "Missed_Percentage", description: "Missed_Percentage" }
    ]
  }
];
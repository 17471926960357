import React        from 'react';
import { Logger }   from 'aws-amplify';
import TextField    from '@material-ui/core/TextField';
import cron         from 'cron-validate'; // https://www.npmjs.com/package/cron-validate

const logger = new Logger('cron');

export default function Cron(props) {
    const [error, setError] = React.useState(false);
    const [msg, setMsg] = React.useState("");
  
    return(
        <React.Fragment>
        <TextField 
            id={props.id} 
            name={props.name} 
            label={props.label} 
            defaultValue={props.defaultValue} 
            helperText={props.helperText} 
            variant="outlined" 
            required={props.required}
            disabled={props.disabled}
            onChange={e => {
                logger.debug("cron", e);
                if (e.target.value) {
                    const cronResult = cron(e.target.value);
                    if (cronResult.isValid()) {
                        const validValue = cronResult.getValue();
                
                        // The valid value is a object containing all cron fields
                        logger.debug("validValue", validValue);
                        setError(false);
                        
                        setMsg(JSON.stringify(validValue));
                      // In this case, it would be:
                      // { seconds: undefined, minutes: '*', hours: '*', daysOfMonth: '*', months: '*', daysOfWeek: '*', years: undefiend }
                    } 
                    else {
                      const errorValue = cronResult.getError();
                    
                      // The error value contains an array of strings, which represent the cron validation errors.
                      setError(true);
                      setMsg(errorValue);
                      logger.debug(errorValue); // string[] of error messages
                    }
                }
                else {
                    setError(false);
                    setMsg("");
                }
                props.onChange(e);
            }} 
            error={error}
            style={{paddingRight:20, paddingBottom: 10, width: props.width}}
            inputProps={props.inputProps}
        />
        <div>{msg}</div>
        </React.Fragment>
    );
}
/**
 * @obsolete - test code only
 */ 
import React, { useState, useContext }    from 'react';
import { Logger }               from 'aws-amplify';
import { makeStyles }           from '@material-ui/core/styles';
import CssBaseline              from '@material-ui/core/CssBaseline';
import View                     from 'pac-responsive-ui-framework/core/View';
import Typography               from '@material-ui/core/Typography';
import Grid                     from '@material-ui/core/Grid';
import Avatar                   from '@material-ui/core/Avatar';
import Paper                    from '@material-ui/core/Paper';
import Link                     from '@material-ui/core/Link';

import MenuOperators            from '../components/MenuOperators';
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import UserService              from '../services/UserService';


const logger = new Logger('Profile');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 75,
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function Profile(props) {
    const app_context = useContext(AppContext);
    const user_service = new UserService();
    
    const [operator, setOperator] = React.useState(app_context.state.user.session.preferred_operator);
    
    const handleMenuCloseCallback = (value) => {
        logger.debug('handleMenuCloseCallback', value);
        const key = app_context.state.user.session.email;
        user_service.update(key, {preferred_operator: value})
            .then((data) => {
                logger.debug("UserService success", data);
                setOperator(value); 
            }).catch((err) => {
                logger.debug('UserService error', err);
            });
        
    }; 

    const classes = useStyles();
  
    return (
        <View title={props.title} fixedContent={true}>
                Put your content here
      </View>
      
    );
    
}

export default function KonosCellRenderer() {
}

KonosCellRenderer.prototype.init = function (params) {
    console.log('KonosCellRenderer.prototype.init', params);
   
    if (params.value) {
        var span = document.createElement('span');
        var a = document.createElement('a');
        var element = params.data;
        
        a.innerText  = params.value;
        a.target = "_blank";
        
        if (element['index'] && element['sys_id']) {
            if (element['konos_number'].substring(0, 2) === "CS") {
                //sys_id: "40eb8749870302501541ed3e8bbb351b"
                //index: "sn_customerservice_case-2024.06"
                a.href = "https://konos.panasonic.aero/now/nav/ui/classic/params/target/sn_customerservice_case.do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D";
            }
            else if (element['konos_number'].substring(0, 6) === "SCTASK") {
                a.href = "https://konos.panasonic.aero/now/nav/ui/classic/params/target/sc_task.do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D";
                
            }
            else if (element['konos_number'].substring(0, 3) === "CHG") {
                a.href = "https://konos.panasonic.aero/now/nav/ui/classic/params/target/change_request.do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D";
            }
            else {
                var index =  element['index'].substring(0, element['index'].indexOf("-"));
                a.href = "https://konos.panasonic.aero/now/nav/ui/classic/params/target/"+index+".do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D";
            }
        } 
        
        span.appendChild(a);
        
        this.eGui = span;
    }
    else this.eGui ="";
};

KonosCellRenderer.prototype.getGui = function () {
    return this.eGui;
};


/**
 * https://www.npmjs.com/package/react-awesome-query-builder
 * https://codesandbox.io/p/github/lbrdar/react-awesome-query-builder/master?file=%2Fpackages%2Fexamples%2Fdemo%2Findex.tsx%3A29%2C34-29%2C49
 * https://github.com/ukrbublik/react-awesome-query-builder/issues/190
 * https://jsonlogic.com/
 */

import React, { useState, useCallback } from "react";
import { Logger }           from 'aws-amplify';
//import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";

import { QueryBuilder, formatQuery, OptionGroup  } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
// see https://react-querybuilder.js.org/demo#addRuleToNewGroups=false&autoSelectField=true&autoSelectOperator=true&debugMode=false&disabled=false&enableDragAndDrop=false&independentCombinators=false&justifiedLayout=false&listsAsArrays=false&parseNumbers=false&resetOnFieldChange=true&resetOnOperatorChange=false&showBranches=false&showCloneButtons=false&showCombinatorsBetweenRules=false&showLockButtons=false&showNotToggle=false&showShiftActions=false&validateQuery=false


import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// For AntDesign widgets only:
//import AntdConfig from "react-awesome-query-builder/lib/config/antd";
//import "antd/dist/antd.css"; // or import "react-awesome-query-builder/css/antd.less";
// For MUI 4/5 widgets only:
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
//import MuiConfig from 'react-awesome-query-builder/lib/config/mui';
// For Bootstrap widgets only:
//import BootstrapConfig from "react-awesome-query-builder/lib/config/bootstrap";

import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles

import attribute_data from "../../data/FilterAttributes";

// Choose your skin (ant/material/vanilla):
const InitialConfig = MaterialConfig; // or MaterialConfig or MuiConfig or BootstrapConfig or BasicConfig

//const {queryBuilderFormat, jsonLogicFormat, queryString, mongodbFormat, sqlFormat, getTree, checkTree, loadTree, uuid, loadFromJsonLogic} = QbUtils;

const logger = new Logger('QueryBuilder1');

logger.debug("InitialConfig", InitialConfig);
// You need to provide your own config. See below 'Config format'

/* Moved to the data folder
const attribute_data = [... ];
*/

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * not needed? 
 */
 /*
function getFieldsFromQuery(query) {
  var fields = [];
  if (query) {
    
    query['rules'].forEach((rule) => {
      if (rule['rules']) {
        fields = fields.concat(getFieldsFromQuery(rule));
      }
      else {
        fields.push(rule.field);
      }
    });
  }
  return fields;
}
*/

export default function QueryBuilder1(props) {

  logger.debug("props", props);

  var rule_list = [
    <MenuItem key="select" value="select">Select a data source</MenuItem>,
  ];
  
  var init_value = props.value?props.value:{};
  logger.debug("init_value", init_value);
  var init_queryType = "select";
  for (const [key, value] of Object.entries(init_value)) {
    if (value['rules'].length) {
      init_queryType = key;
    }  
  }
  /*
  attribute_data.forEach((sources) => {
    if (!init_value[sources.source]) init_value[sources.source] = null; 
  });
  */
  logger.debug("init_value", init_value);

  const [tab, setTab] = useState(0);
  //const [rule, setRule] = useState("ALL");
  const [error, setError] = useState("");
  //const [json, setJSON] = useState("");
  const [querySet, setQuerySet] = useState(init_value);
  const [queryType, setQueryType] = useState(init_queryType);
  
 
  
  //var query_fields;
  //var query_fields = getFieldsFromQuery(query);
  //var new_query_fields = [];

  var fields = [];
  //var attributes = [];
  attribute_data.forEach((sources) => {
    rule_list.push(<MenuItem key={sources.source} value={sources.source}>{sources.label}</MenuItem>);

  
  
  
    sources.columns.forEach((column) => {
  
      
      var new_column = {...column};
     // new_column.name = sources['source'] + "." + new_column.name;
    //  new_column.key = sources['source'] + "." + new_column.name;
      
      /*
      //logger.debug("attribute column", column, new_column);
      var in_query = false;
      query_fields.forEach((field) => {
        if (new_column.name == field) 
        in_query = true;
      });
      */
      if (queryType === sources.source) {
        fields.push(new_column);
        
      }
      /*
      if (queryType === 'ALL') {
        fields.push(new_column);
      }
      else 
      else if (in_query) {
        fields.push(new_column);

      }
      */
    });
  });
  /*
  logger.debug("fields", fields);
  query_fields.forEach((field) => {
    fields.push(field);
  });
  */
  /*
  const onQueryChange = useCallback((immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    logger.debug("onChange", immutableTree, config);
    setState({ ...state, tree: immutableTree, config: config });

    const jsonTree = QbUtils.getTree(immutableTree);
    console.log(jsonTree);
    props.handleChange({target: {name: props.name, value: JSON.stringify(jsonTree)}});
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);
  */
  
  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      //setJSON(JSON.stringify(logicJSON));

    }
    setError(""); 
    setTab(newValue);
  };

  const onTextChange = useCallback((event) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    logger.debug("onTextChange", event);
    
    return;
    
    var error = false;
    var value = event.target.value;
    //setJSON(event.target.value);
    if (value.trim() != "") {

      try {
      var json = JSON.parse(event.target.value);
      }
      catch(e) {
        error = "Invalid JSON";
      }
      logger.debug("onTextChange error", error);
    }
    if (error !== false) {
      setError(error);
    }
    else {
      setError("");
      //var treeObj = checkTree(loadFromJsonLogic(json, state.config), state.config); // <- this will work same
     // logger.debug("onTextChange checktree", treeObj);

      /*
      if (treeObj) {
        setState({
          ...state,
          tree: treeObj,
        });
      }
      else if (value.trim() != "") {
          error = "Invalid structure";
      }
      */
    }
    setError(error);
  }, []);

  var displayError = '';
  if (error) displayError = error;
  else {
    // check errorsJSON
  }

  const handleQueryChange = (data) => {
    //var tmp_query_set_data = {};
    var tmp_query_set = {}; //JSON.parse(JSON.stringify(querySet));
    tmp_query_set[queryType] = data;
    
    logger.debug("handleQueryChange", tmp_query_set);
    
    // This should only happen the first time
    
    attribute_data.forEach((sources) => {
      if (!tmp_query_set[sources['source']]) {
        tmp_query_set[sources['source']] =  {
          combinator: 'and',
          rules: [],
        };
      }
    });
    
    setQuerySet(tmp_query_set);
    
    
    props.onChange(
      {
        target: {
          name: props.name,
          value: JSON.stringify(tmp_query_set) //JSON.stringify(tmp_query_set)
        }
      }
    );
    
    // format for jsonlogic
    var tmp_query_set_json = {};
    for (const [key, value] of Object.entries(tmp_query_set)) {
      logger.debug("jsonlogic", key, value);
      tmp_query_set_json[key] = formatQuery(value, "jsonlogic") ;
    }
    
     props.onChange(
      {
        target: {
          name: props.name + "_jsonlogic",
          value: JSON.stringify(tmp_query_set_json) //JSON.stringify(formatQuery(tmp_query_set_json, "jsonlogic"))
        }
      }
    );
  };
  
  
  var query_builder;
  if (queryType != 'select')
    query_builder =<QueryBuilder fields={fields} query={querySet[queryType]} onQueryChange={handleQueryChange} />;
      
      
  logger.debug("queryType", queryType, typeof(querySet[queryType]), querySet);  

  return (
    <div>
      <Select
          labelId="query_type"
          id="query_type"
          value={queryType}
          onChange={
            (event) => {
              logger.debug("onChange");
              setQueryType(event.target.value);
            }
          }
          style={{margin: "8px 0px 8px 10px"}}
        >
         {rule_list}
      </Select>
      
      {/* builder */}
      <div hidden={false} style={{paddingRight: "8px"}}>
        
        {query_builder}
        
      </div>
      
      {/* JSON */}
      {/*
      <div hidden={tab !== 1} className="query-builder-result"> 
        <div>
          <pre>
            {typeof(querySet[queryType]) !== 'undefined'?formatQuery(querySet[queryType], "json"):""}
          </pre>
        </div>
      </div>
      {/* Editor - not used
      <div hidden={tab !== 1} style={{padding: "28px"}} >
        <TextField
          fullWidth
          id="filled-multiline-flexible"
          label=""
          multiline
          maxRows={20}
          value={formatQuery(querySet[queryType], 'json')}
          //onChange={handleChange}
          variant="outlined"
          inputProps={{style: {fontFamily: "Lucida Console, Courier New, monospace", fontSize: "14px"}}}
          onChange={onTextChange}
        />
        <div style={{color: "#ff0000"}}>
          {displayError}
        </div>
      </div>
      */}
      {/* JSON Logic*/}
      {/*}
      <div hidden={tab !== 2} className="query-builder-result"> 
        <div>
          <pre>
            {typeof(querySet[queryType]) !== 'undefined'?JSON.stringify(formatQuery(querySet[queryType], "jsonlogic")):""}
          </pre>
        </div>
      </div>
     
      {/* JSON Logic Encoded*/}
      {/*
      <div hidden={tab !== 3} className="query-builder-result"> 
        <div>
          <pre>
            {typeof(querySet[queryType]) !== 'undefined'?JSON.stringify(formatQuery(querySet[queryType], "jsonlogic")):""}
          </pre>
        </div>
      </div>
      
      {/* SQL */}
      {/*}
      <div hidden={tab !== 4} className="query-builder-result"> 
        <div>
          <pre>
             {typeof(querySet[queryType]) !== 'undefined'?formatQuery(querySet[queryType], "sql"):""}
          </pre>
        </div>
      </div>
      */}
    </div>
  );
}
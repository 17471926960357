
export default function CronRuleRenderer() {
}

CronRuleRenderer.prototype.init = function (params) {
     this.eGui = document.createElement('span');
    console.log('CronRuleRenderer.prototype.init', params);
    if (params.value.name) {
        this.eGui.innerHTML = params.value.name;
    }
    else this.eGui.innerHTML = '';
};

CronRuleRenderer.prototype.getGui = function () {
    return this.eGui;
};


/**
 * This is the main landing page, and contains a catalog of dashboards for the user to select from.
 
  Example of News structure
  
    {
      "last_updated_date": "2022-06-23T00:35:59.767Z",
      "config": [
        {
          "date": "2022-06-23",
          "description": "Currently connectivity data processing is experiencing delays.<br>Please wait",
          "active": true
        },
        {
          "date": "2022-06-27",
          "description": "Currently connectivity data processing is experiencing delays.<br>Please wait",
          "active": true
        }

      ],
      "created_date": "2022-06-23T00:27:13.646Z",
      "SK": "v0",
      "PK": "NEWSCONFIG|ORIGINS",
      "revisions": 3
    }
    
    {
      "last_updated_date": "2022-06-23T00:35:59.767Z",
      "config": [
        {
          "date": "2022-06-23",
          "description": "Currently connectivity data processing is experiencing delays.<br>Please wait",
          "active": true
        },
        {
          "date": "2022-06-27",
          "description": 'This is a <a href="google.com" target="_blank">test</a> of a URL link in the description.',
          "active": true
        },
        {
          "date": "2022-06-27",
          "description": 'This is a paragraph test of various messages<br> You may want another line of text.',
          "active": true
        },
        {
          "date": "2022-06-27",
          "description": '<p>This is a paragraph test of various messages</p><p style="border: solid 1px red">You may want another line of text.</p>',
          "active": true
        },
        {
          "date": "2022-06-27",
          "description": 'This is a paragraph test of various messages<p style="border: solid 1px red">You may want another line of text.</p>',
          "active": true
        }

      ],
      "created_date": "2022-06-23T00:27:13.646Z",
      "SK": "v0",
      "PK": "NEWSCONFIG|ORIGINS",
      "revisions": 3
    };

 
 */
import React, { useContext, useState } from 'react';
import { Logger } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ReportGrid from '../components/ReportGrid6';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/ArrowRight';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { constants } from 'assets/Config.js';
import View from 'pac-responsive-ui-framework/core/View';
import NewsService from '../services/NewsService';

import AppContext from 'pac-responsive-ui-framework/core/AppContext';

//import Dashboards       from '../data/Dashboards';
import {
    setReportList,
    getRecentReportList,
    getReportList,
    setFavoriteReports,
    //getFavoriteReportList
}
from '../data/ReportList';
// comment
import News from '../components/News';

import ThirdPartyCookieSupport from '../contexts/ThirdPartyCookieSupport';

const logger = new Logger('Home');

export default function Home() {

    const [news, setNews] = useState();
    const [news_loaded, setNewsLoaded] = useState(false);


    const app_context = useContext(AppContext);
    setReportList(app_context.state.reports_list);
    setFavoriteReports(app_context.state.favorite_reports);

    const RecentReportListData = getRecentReportList(app_context);
    logger.debug("RecentReportListData", RecentReportListData);
    
    

    const ReportListData = getReportList([], app_context.state);
    logger.debug("ReportListData", ReportListData);
    
    var FavoriteReportListData = [];
    ReportListData.forEach((element) => {
      if (element.favorite) {
        FavoriteReportListData.push(element);
      }
    });
    logger.debug("FavoriteReportListData", FavoriteReportListData);
    
    var FeaturedReportListData = [];
    var new_report_list = {};
    ReportListData.forEach((val, index) => {
      if (val.preferred) {
        FeaturedReportListData.push(val);
      
      }
      if (val.section != "Experimental") {
        if (!new_report_list[val.section]) new_report_list[val.section] = [];
        new_report_list[val.section].push(val);
      }
    });
    logger.debug("new_report_list", new_report_list);
    
    
    var report_grids = [];
    Object.entries(new_report_list).forEach(([key, value]) => {
        logger.debug('report_grids', key, value);
        report_grids.push(<ReportGrid key={key} title={key} report_list={value} limit={9} />); 
    });
    
      

    const news_service = new NewsService();
    if (!news_loaded) {
        setNewsLoaded(true);
        news_service.fetch(constants.app)
            .then((data) => {
                logger.debug("news_service success", constants.app, data);
                setNews(data);
            })
            .catch((err) => {
                logger.debug('news_service failed', constants.app, err);
            });
    }

  /*
    var message = (
        <React.Fragment>
      <Typography variant="h4">&nbsp;Welcome to {constants.title}.</Typography>
      <Box p={1}>
        <Typography variant="body1">{constants.tagline}</Typography>
      </Box>
    </React.Fragment>
    )
    */
    var message = "";

    //* Special message
    var news_message2 = [];
    if (news && news.config) {
        var message2 = []
        var top = true;
        news.config.forEach((item) => {

            if (item.active) {
                var title = item.title;
                if (item.news_href) {
                    title = <a href={item.news_href} target="_blank">{title}</a>
                }
                var header;
                if (top) header = <Box pb={1}><Typography variant="body1"><b>Latest News</b></Typography></Box>

                var description = (<span dangerouslySetInnerHTML={{ __html: item.description }}></span>);
                message2.push(
                    <React.Fragment>
            <Box p={1}>
              {header}
              <Typography variant="body1">
                <p><i>{item.date}</i> {description}</p>
                
              </Typography>
             
            </Box>
          </React.Fragment>
                )
            }
            top = false;

        })
        if (message2.length)
            news_message2.push(<News message={message2} />);
    }
    //*/

    var cookie_message = (
        <React.Fragment>
       <Grid container spacing={2}>
          <Grid item>
            <ReportProblemIcon />
          </Grid>
          <Grid item xs={12} sm container>
             <Typography variant="body1">
              You need to change a browser setting which is preventing reports from loading properly. Please click the link below for information on how to make this change.
            </Typography>
            <Box p={1}>
              <Link  
                  target="_blank"
                  to={{ pathname: constants.faq_url }}
              ><InfoIcon fontSize="small"/>Origins - FAQ: Instructions for enabling third party cookies</Link >
              </Box>
          </Grid>
        </Grid>
    </React.Fragment>
    );

    return (
        <View title="Home"> 
    
      <ThirdPartyCookieSupport>
        <News message={cookie_message} />
      </ThirdPartyCookieSupport>
      <News message={message} />
      {news_message2}
      <ReportGrid title="Favorites" report_list={FavoriteReportListData} size="small"/> 
      <ReportGrid title="Recently Viewed" report_list={RecentReportListData} size="small"/> 
      <ReportGrid title="Core Dashboards" report_list={FeaturedReportListData} size="small"/> 
      
      {/*<ReportGrid title="Dashboards" report_list={ReportListData} />*/} 
      {report_grids}
      <div style={{height: "20px"}}></div>
    </View>
    );
}

import React, { useState } from 'react';
import { Logger } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@mui/material/Grid2';
import CardReport from './CardReport6';
import Button from '@mui/material/Button';
const logger = new Logger('ReportGrid');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

export default function ReportGrid(props) {
    logger.debug("props", props);
    
    const [expand, setExpand] = useState(false);


    const classes = useStyles();

    let showSubtitles = props.showSubtitles !== undefined ? props.showSubtitles : true;

    const getGrid = (report_list) => {
        return <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="stretch"
                >
                    {report_list.map((report_details, index) => {
                        // Dynamically set category:
                        // 1. Use report_details.category for Favorites.
                        // 2. Use props.category for Recent Reports or Home.
                        const categoryName = report_details.category || props.category || '';
                        return (
                            <Grid
                                xs={12} sm={6} md={6} lg={6} xl={6}
                                key={index}
                            >
                                <CardReport
                                    title={report_details.title}
                                    subtitle={report_details.subtitle}
                                    href={report_details.href}
                                    docs_href={report_details.docs_href}
                                    target={report_details.target}
                                    image={report_details.image}
                                    customer_icon={report_details.customer_icon}
                                    tags={report_details.tags}
                                    report_environments={report_details.report_environments}
                                    report_type={report_details.report_type}
                                    showSubtitle={showSubtitles}
                                    favorite={report_details.favorite}
                                    default_operator={report_details.default_operator}
                                    size={props.size}
                                    last_visit_time={report_details.last_visit_time}
                                    category={categoryName} // Updated logic
                                    title_icon={report_details.title_icon}
                                    features={report_details.features}
                                />
                            </Grid>
                        );
                    })}
                </Grid>;
    };

    if (props.report_list.length) {
        var report_list = [];
        var extended_report_list = [];
        var expand_button;
        logger.debug("report_list1", report_list);
        if (props.limit) {
            var count = 0;
            props.report_list.forEach((element) => {
                if (count < props.limit) {
                    report_list.push(element);
                }
                else {
                    if (expand === true) {
                        extended_report_list.push(element);
                        expand_button =  <Button onClick={() => setExpand(false)}>Fewer</Button>;
                    }
                    else {
                        expand_button =  <Button onClick={() => setExpand(true)}>More</Button>;
                    }
                }
                count++;
                
            });
        }
        else report_list = props.report_list;
        
        return (
            <div className={classes.root}>
                <Box mt={1} mb={1}>
                    <Typography variant="h5" component="h5" style={{paddingTop: "20px"}}>
                        {props.title}
                    </Typography>
                </Box>
                {getGrid(report_list)}
                {expand_button}
                {getGrid(extended_report_list)}
            </div>
        );
    } else {
        return null;
    }
}

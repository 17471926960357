import React, { useEffect, useContext } from 'react';
import { Logger }                   from 'aws-amplify';

import Tooltip from '@material-ui/core/Tooltip';
import FilterIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';

/*
import Grid from '@material-ui/core/Grid';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker
   
} from '@material-ui/pickers';
*/
import DateTimeFilter from '../../components/FormElements/DateTime';

import OperatorSelect   from '../../components/FormElements/Select/Operator';
import AircraftSelect   from '../../components/FormElements/Select/Aircraft';
import RuleNameSelect   from '../../components/FormElements/Select/RuleName';
import AppContext       from 'pac-responsive-ui-framework/core/AppContext';

const logger = new Logger('FormFilters/Flight');

/**
 *  Props:
 *      start_date
 *      end_date
 *      operator JSON object containing operator_name, operator_icao
 *      aircraft JSON object containing registration_name, ship_number, system, type, etc. 
 */ 
export default function Flight(props) {
    logger.debug("props", props);
    /*
    const app_context = useContext(AppContext);
    var props_operator;
    if (props.state.operator) {
      
        if (app_context.state.cache && app_context.state.cache.operators) {
            logger.debug("operator_cache", app_context.state.cache.operators);
            app_context.state.cache.operators.data.forEach(function(value, key) {
                if (value.operator_icao_code == props.state.operator.operator_icao_code) {
                    props_operator = value;
                }
            });
        }
        
        
        logger.debug("setting operator1", props_operator);
    }
    */
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [startDate, setStartDate] = React.useState(props.state.start_date);
    const [endDate, setEndDate] = React.useState(props.state.end_date);
    const [operator, setOperator] = React.useState(props.state.operator);
    const [aircraft, setAircraft] = React.useState(props.state.aircraft);
    const [ruleName, setRuleName] = React.useState(props.state.ruleName);
    const [wasClosed, setWasClosed] = React.useState(false);
    const [operatorSet, setOperatorSet] = React.useState(false);
    
    const maxDate = new Date().toISOString().substr(0, 10) + ' 23:59:59';
    

    const operatorLabel = (option) => {
        if (option.operator_icao_code && option.operator_name) {
             return option.operator_icao_code + ' - ' + option.operator_name;
        }
        else if (option.operator_icao_code) {
             return option.operator_icao_code;
        }
        else {
            return option.operator_name;
        }
    };
   
    const aircraftLabel = (option) => {
        var label = option.registration_name;
        if (option.ship_number) {
             label += " (" + option.ship_number + ')'; 
        }
        return label;
    };
    
    var invalidState = false;
    var startDateError = false;
    var startDateMessage;
    var endDateError = false;
    var endDateMessage;
    logger.debug("invalid state", startDate, endDate, operator, props.state.operator);
    if (!operator) invalidState = true;
    
    if (!startDate || !endDate) {
      invalidState = true;
    }
    
    if (startDate > maxDate) {
         logger.debug("invalid date");
        invalidState = true;
        startDateError = true;
        startDateMessage = "Cannot have a future date";
    }
    if (endDate > maxDate) {
         logger.debug("invalid date");
        invalidState = true;
        endDateError = true;
        endDateMessage = "Cannot have a future date";
    }

    if (startDate > endDate) {
         logger.debug("invalid date");
        invalidState = true;
        endDateError = true;
        endDateMessage = "End Date must be later than Start Date";
    }
    var found = true;
    var start_date_text =  "Start Date: ____-__-__";
    if (props.state.start_date) {
    start_date_text =  "Start Date: " + props.state.start_date.substr(0,16);
    }
    else found = false;
    if (props.state.start_date > props.state.end_date) {
        start_date_text =<span style={{color: "red"}}>{"End Date: " + props.state.start_date.substr(0,16)}</span>;
    }
    var end_date_text =  "End Date: ____-__-__";
    if (props.state.end_date) {
        end_date_text = "End Date: " + props.state.end_date.substr(0,16);
    }
    else found = false;
    if (props.state.end_date < props.state.start_date) {
        end_date_text =<span style={{color: "red"}}>{"End Date: " + props.state.end_date.substr(0,16)}</span>;
    }
    
       
    var operator_text;
    if (props.state.operator) {  
        operator_text = " | Operator: " + operatorLabel(props.state.operator);
    }
   
    var aircraft_text;
    if (props.state.aircraft) {
        aircraft_text = " | Aircraft: " + aircraftLabel(props.state.aircraft);
    }
    
    var header_text= <React.Fragment>&larr; Select your filters here</React.Fragment>;
    if (found) {
         header_text= <React.Fragment>{start_date_text} | {end_date_text}{operator_text}{aircraft_text}</React.Fragment>;
    }
   
    
    //var header_text= <React.Fragment>{start_date_text} | {end_date_text}{operator_text}{aircraft_text}</React.Fragment>;
     
    
    
    const handleStartDateChange = (date) => {
      setStartDate(date);
    };
    
    const handleOpen = (event) => {
        logger.debug('handleOpen', event);
        setAnchorEl(event.currentTarget);
    };

    const handleCancel = () => {
        logger.debug('handleCancel');
        //setStartDate(props.state.start_date);
        //setEndDate(props.state.end_date);
        //setOperator({operator_icao_code: props.state.operator});
        //setAircraft(props.state.aircraft);
        setWasClosed(true);
        setAnchorEl(null);
    };
    
    const handleSave = () => {
        logger.debug("handleSave", {start_date: startDate, end_date: endDate, operator: operator, aircraft: aircraft});
        props.onChange({start_date: startDate, end_date: endDate, operator: operator, aircraft: aircraft});
        setWasClosed(true);
        setAnchorEl(null);
    };
    
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    
     const handleOperatorChange = (value) => {
        logger.debug("handleOperatorChange", value);
        setOperator(value.target.value);
        setAircraft(null);
        setOperatorSet(true);
    };
    
     const handleAircraftChange = (value) => {
        logger.debug("handleAircraftChange", value);
        setAircraft(value.target.value);
    };
    
     const handleRuleNameChange = (value) => {
        setRuleName(value.target.value);
    };
    
    var anchorRef = React.createRef();
    
    useEffect(() => {
        if (wasClosed == false)
            setAnchorEl(anchorRef.current);
    });
    
    useEffect(() => {
        console.log(operator, '- Has changed')
    },[operator]) 
    
    
    var open = Boolean(anchorEl);
    logger.debug("anchorEl", anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
   
        <React.Fragment>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <Tooltip title="Data Filters">
                                <IconButton onClick={handleOpen}>
                                    <FilterIcon ref={anchorRef}/>
                                </IconButton>
                            </Tooltip>
                        </td>
                        <td>
                            {header_text}
                        </td>
                    </tr>
                </tbody>
            </table>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCancel}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                
            >
            <div style={{padding: "16px"}}>
                <DateTimeFilter 
                        id="start" 
                        dateLabel="Start Date" 
                        timeLabel="Start Time" 
                        defaultValue={startDate}  
                        onChange={handleStartDateChange}
                        maxDate={maxDate}
                        error={startDateError}
                        helperText={startDateMessage}
                        required
                /><br/>
                <DateTimeFilter 
                        id="end" 
                        dateLabel="End Date" 
                        timeLabel="End Time" 
                        defaultValue={endDate} 
                        onChange={handleEndDateChange}
                       // minDate={startDate} 
                        maxDate={maxDate}
                        error={endDateError}
                        helperText={endDateMessage}
                        required
                        //minDateMessage="End Date cannot precede Start Date"
                        //maxDateMessage={"End Date must be within " + days_diff + " days of the Start Date"}
                />
                <OperatorSelect 
                    handleChange={handleOperatorChange} 
                    defaultValue={operator} 
                    options={operator ? [operator] : null}
                    required
                />
                <AircraftSelect 
                    handleChange={handleAircraftChange} 
                    defaultValue={aircraft} 
                    options={aircraft ? [aircraft] : null}
                    operator_icao_code = {operator ? operator.operator_icao_code : ""}
                />
               <RuleNameSelect 
                    handleChange={handleRuleNameChange} 
                    defaultValue={ruleName} 
                    // options={aircraft ? [aircraft] : null} //as it it dummy now so not passing from here
                    operator_icao_code = {operator ? operator.operator_icao_code : ""}
                />
            </div>
            <Grid container justifyContent="flex-end">
                <div style={{padding: "8px"}}>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={invalidState}>Submit</Button>
                </div>
            </Grid>
            <div style={{paddingLeft: 8, paddingBottom: 8}} > * required</div>
      </Popover>

    </React.Fragment>
    
  );
}
import React, { useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";

const menuItems = [
  {
    label: "Assign to DevOps",
    children: [
      { label: "DevOps-COMS", onClick: () => null},
      { label: "DevOps-ZT", onClick: () => null},
      { label: "DevOps-Data", onClick: () => null},
      { label: "DevOps-CPC", onClick: () => null},
      { label: "DevOps-IFC ", onClick: () => null},
    ],
  },
  {
    label: "Need field investigation",
    onClick: () => null,
  },
  {
    label: "Ignore, Fix in-progress",
    onClick: () => null,
  },
   {
    label: "Ignore, Transient",
    onClick: () => null,
  },
   {
    label: "DevOps Monitor",
    onClick: () => null,
  },
];

const DynamicDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
    setActiveSubmenu(null);
  };

  const handleSubmenuOpen = (event, index) => {
    setSubmenuAnchorEl(event.currentTarget);
    setActiveSubmenu(index);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
    setActiveSubmenu(null);
  };

  return (
    <div>
      <div onClick={handleMenuOpen} variant="contained">
        {menuItems[Math.floor(Math.random() * menuItems.length)].label}
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map((item, index) => (
          <div key={index}>
            {item.children ? (
              <MenuItem
                onMouseEnter={(event) => handleSubmenuOpen(event, index)}
                onMouseLeave={handleSubmenuClose}
              >
                {item.label}
                <Menu
                  anchorEl={submenuAnchorEl}
                  open={activeSubmenu === index}
                  onClose={handleSubmenuClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  {item.children.map((subItem, subIndex) => (
                    <MenuItem key={subIndex} onClick={subItem.onClick}>
                      {subItem.label}
                    </MenuItem>
                  ))}
                </Menu>
              </MenuItem>
            ) : (
              <MenuItem onClick={item.onClick}>{item.label}</MenuItem>
            )}
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default function App() {
  return <DynamicDropdown />;
}
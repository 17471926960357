
export default function URLCellRenderer() {
}

URLCellRenderer.prototype.init = function (params) {
   
        if (params.value) {
            console.log('URLCellRenderer.prototype.init value', params.value);
            var obj;    
            
            try {
                new URL(params.value);
                obj = [{name: params.value, href: params.value}];
            } catch (_) {
                console.log('URLCellRenderer.prototype.init catch1', params);
                try {
                    obj = JSON.parse(params.value);
                    
                }
                catch (e) {
                    console.log('URLCellRenderer.prototype.init catch2', params);
                
                    // obj is null
                }
            }
            var span = document.createElement('span');
            if (obj) {
                console.log('URLCellRenderer.prototype.init obj', obj);
               
                 
                obj.forEach((element, index) => {
                    var name = element.name;
                    if (index > 0) span.appendChild(document.createTextNode(" | "));
                    if (element.href) {
                        var a = document.createElement('a');
                        a.innerText  = name;
                        a.target = "_blank";
                        a.href = element.href;
                        span.appendChild(a);
                    }
                    else span.appendChild(document.createTextNode(name));
                });
                this.eGui = span;
            }
            else {
                console.log('URLCellRenderer.prototype.init not obj', params.value);
                span.appendChild(document.createTextNode( params.value));
                this.eGui = span;
            }
        }
        else this.eGui ="";
    
};

URLCellRenderer.prototype.getGui = function () {
    return this.eGui;
};


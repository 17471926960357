import OauthService from './OauthService';
import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';
//import cron         from 'cron-validate'; // https://www.npmjs.com/package/cron-validate
import CronRuleRenderer from "../components/AGGrid/CronRuleRenderer";
import attribute_data from "../data/FilterAttributes";
import ActionDropdownRenderer from '../components/ActionDropdownRenderer';
const logger = new Logger('DataFilterEventsService');



// TODO : Remove this mock data later
const mockAPIDataFilterEvents =  [
  {
    rule_number: "H-4db40576-8a2b-4ccd-9b1f-03ae573f8bcf",
    rule_name: "Headend - Hardware No Response",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "N343NB",
    operator: "DAL",
    ac_type: "787",
    total_hits: 10,
    total_flights: 5,
    severity_prediction: "90%",
  },
  {
    rule_number: "H-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Headend - Degraded Laser Power",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "N075UA",
    operator: "UAL",
    ac_type: "A320",
    total_hits: 7,
    total_flights: 2,
    severity_prediction: "70%",
  },
  {
    rule_number: "H-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Headend - Link break",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "A6-EIU",
    operator: "ETD",
    ac_type: "A380",
    total_hits: 5,
    total_flights: 3,
    severity_prediction: "60%",
  },
  {
    rule_number: "H-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Headend - Software application watchdog",
    rule_type: "Anomaly",
    team: "Headend",
    Action: "",
    tail_number: "C-GROV",
    operator: "ACN",
    ac_type: "737",
    total_hits: 15,
    total_flights: 6,
    severity_prediction: "10%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - EnableFrontRJ45",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "B-17811",
    operator: "EVA",
    ac_type: "787",
    total_hits: 10,
    total_flights: 7,
    severity_prediction: "25%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - CreateOffload",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "A7-BAZ",
    operator: "QTR",
    ac_type: "A320",
    total_hits: 15,
    total_flights: 1,
    severity_prediction: "10%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - ExConnSetWapRadioStatus",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "GCS037",
    operator: "TST",
    ac_type: "737",
    total_hits: 35,
    total_flights: 2,
    severity_prediction: "65%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - StartBiteTest",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "CC-BGB",
    operator: "LAN",
    ac_type: "747",
    total_hits: 40,
    total_flights: 3,
    severity_prediction: "20%",
  },
  {
    rule_number: "M-0f6dc25a-fe3e-4803-a273-32d8178a1eec",
    rule_name: "Maint - ResetSeatByRange",
    rule_type: "Anomaly",
    team: "Maint",
    Action: "",
    tail_number: "B-HLM",
    operator: "CPA",
    ac_type: "757",
    total_hits: 67,
    total_flights: 6,
    severity_prediction: "15%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - Interactive Seat Reset",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "N14115",
    operator: "UAL",
    ac_type: "777",
    total_hits: 13,
    total_flights: 9,
    severity_prediction: "2%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - Internet Access Control",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "EI-LRD",
    operator: "EIN",
    ac_type: "A320",
    total_hits: 11,
    total_flights: 3,
    severity_prediction: "5%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - PED Power Control",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "SU-GFL",
    operator: "MSR",
    ac_type: "A380",
    total_hits: 23,
    total_flights: 4,
    severity_prediction: "9%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - USB Power Control",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "JA462A",
    operator: "ANA",
    ac_type: "777",
    total_hits: 9,
    total_flights: 4,
    severity_prediction: "33%",
  },
  {
    rule_number: "Q-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "QT CM GUI - Full Seat Reset",
    rule_type: "Anomaly",
    team: "CM-GUI",
    Action: "",
    tail_number: "D-AXGF",
    operator: "OCN",
    ac_type: "787",
    total_hits: 5,
    total_flights: 6,
    severity_prediction: "50%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Opportunity Spike",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "UAL",
    ac_type: "757",
    total_hits: 1000,
    total_flights: 8,
    severity_prediction: "25%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Opportunity for Invalid Zones",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "FIN",
    ac_type: "747",
    total_hits: 1500,
    total_flights: 9,
    severity_prediction: "45%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Opportunity for Invalid Sizes",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "ICE",
    ac_type: "A320",
    total_hits: 1222,
    total_flights: 8,
    severity_prediction: "88%",
  },
  {
    rule_number: "O-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "One Media - Missed Impression for active campaigns",
    rule_type: "SLA",
    team: "One Media",
    Action: "",
    tail_number: "",
    operator: "JAL",
    ac_type: "A380",
    total_hits: 5672,
    total_flights: 4,
    severity_prediction: "97%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Hardware No Response+Maint - ResetSeatByRange",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "N14115",
    operator: "UAL",
    ac_type: "777",
    total_hits: 11,
    total_flights: 3,
    severity_prediction: "10%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Degraded Laser Power+QT CM GUI - USB Power Control",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "EI-LRD",
    operator: "EIN",
    ac_type: "A320",
    total_hits: 13,
    total_flights: 5,
    severity_prediction: "22%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Maint - ResetSeatByRange+QT CM GUI - Interactive Seat Reset",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "SU-GFL",
    operator: "MSR",
    ac_type: "A380",
    total_hits: 22,
    total_flights: 9,
    severity_prediction: "33%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Link break+QT CM GUI - Internet Access Control",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "JA462A",
    operator: "ANA",
    ac_type: "777",
    total_hits: 6,
    total_flights: 5,
    severity_prediction: "45%",
  },
  {
    rule_number: "C-952dc9ea-bcff-44da-8a32-e7ebfb15c7f6",
    rule_name: "Headend - Hardware No Response+QT CM GUI - Full Seat Reset",
    rule_type: "Composite",
    team: "Data",
    Action: "",
    tail_number: "D-AXGF",
    operator: "OCN",
    ac_type: "787",
    total_hits: 20,
    total_flights: 4,
    severity_prediction: "65%",
  },
];;

export default class DataFilterEventsService extends BaseService {
    constructor() {
        let apiName = 'RulesEngineAPI';
        let collectionPath = '/report';
        super(apiName, collectionPath);
    }

    async fetchRecords(recordId, params = {}) {

        return Promise.resolve(mockAPIDataFilterEvents);
    }

    /**
 * {
 *   "enabled": true,
 *   "filter_name": "hardware_error",
 *   "filter_description": "hardware_error",
 *   "filter_query": "{\"tsyslog_bite\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[{\"id\":\"3fd7afe3-d3b6-4c8a-be5a-2568aa70e5be\",\"field\":\"bite_code\",\"operator\":\"=\",\"valueSource\":\"value\",\"value\":\"1\"},{\"id\":\"2e3c6e96-31e4-4270-a14f-d0e5a5b6248b\",\"field\":\"mmid\",\"operator\":\"LIKE\",\"valueSource\":\"value\",\"value\":\"00001\"}],\"combinator\":\"and\",\"not\":false},\"dl_pac_origins_aircraft_v1.logs\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[],\"combinator\":\"and\",\"not\":false},\"dl_pac_origins_pacwisplive_v1.wisp_session_connect\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[],\"combinator\":\"and\",\"not\":false},\"dl_pac_dp_fds_raw_prod_v1.mst_fds_flight_events\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[],\"combinator\":\"and\",\"not\":false}}",
 *   "created_date": 1738326951.483211,
 *   "created_by": "vinod.hiremath@panasonic.aero",
 *   "last_updated_date": 1738326951.496676,
 *   "last_updated_by": "vinod.hiremath@panasonic.aero"
 * }
 */

    formatRowData(data) {
        logger.debug("formatRowData0", data, data.data);
        return data;
    }

    getMetaData(data) {
        logger.debug("getMetaData", data);
        return {
            pagination: false,
            total_records: data.length
        };
    }

    /**
* If this function doesn't exist, the assumptino is it is true
* The function is never actually called when inserting a record.
*/
    allowed_insert() {
        return false;
    }

    /**
 * {
 *   "enabled": false,
 *   "filter_name": "filter",
 *   "filter_description": "Updated description",
 *   "filter_query": "{updated query}",
 *   "user": "someother_user@pansonic.aero"
 * }
 */
    async save(data, metaData) {
        logger.debug("save1", data, metaData);
    }


    getColumnDefs() {
        return DataFilterEventsService.getColDefs();
    }

    /**
       * This needs to be static to have the other static functions access it.
       */
    static getColDefs() {
        var column_width = 100;

        return [
            {
                headerName: 'Rule Details',
                defaultExpanded: true,
                children: [
                    {
                        field: "rule_name",
                        headerName: "Rule Name",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        required: true,
                    },
                    {
                        field: "rule_type",
                        headerName: "Rule Type",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "select",
                        options: ["Anomaly Rule", "C-Rule"],
                        required: true,
                    },
                    {
                        field: "team",
                        headerName: "Team",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "select",
                        options: ["Headend", "Maint", "CMI GUI"],
                        required: true,
                    },
                    {
                        field: "action",
                        headerName: "Action",
                        filter: 'agTextColumnFilter',
                        cellRendererFramework: ActionDropdownRenderer,
                        width: column_width*2,
                        resizable: true,
                        formType: "select",
                        options: [
                          "Assign to DevOps COMS",
                          "Assign to DevOps ZT",
                          "Assign to DevOps Data",
                          "Assign to DevOps CPC",
                          "Assign to DevOps IFE",
                          "Need Field Investigation",
                          "Ignore, Fix in progress",
                          "Ignore Transient",
                          "DevOps Monitor"
                        ],
                        required: false,
                    },
                    {
                        field: "tail_number",
                        headerName: "Tail Number",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text",
                        required: false,
                    },
                    {
                        field: "total_hits",
                        headerName: "Total Hits",
                        filter: 'agNumberColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "number",
                        required: false,
                    },
                    {
                        field: "total_flights",
                        headerName: "Total Flights",
                        filter: 'agNumberColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "number",
                        required: false,
                    },
                    {
                        field: "severity_prediction",
                        headerName: "Severity Prediction(%)",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        required: false,
                    },
                    {
                        field: "operator",
                        headerName: "Operator",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text",
                        required: false,
                    },
                    {
                        field: "ac_type",
                        headerName: "AC Type",
                        filter: 'agTextColumnFilter',
                        width: column_width * 2,
                        resizable: true,
                        formType: "text",
                        required: false,
                    },
                    {
                        field: "rule_number",
                        headerName: "Rule #",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text",
                        required: true,
                    },
                    {
                        field: "rule_level",
                        headerName: "Rule Level",
                        filter: 'agTextColumnFilter',
                        width: column_width,
                        resizable: true,
                        formType: "text",
                        required: false,
                    },
                     {
                        field: "duplicate",
                        headerName: "Note: There is a similar case created on this in last 3 days. Check this box to overrule. ",
                        filter: 'agTextColumnFilter',
                        hide: true,
                        width: column_width,
                        resizable: true,
                        formType: "boolean",
                        required: false,
                        newline: true
                    },
                ]
            }
        ];
    }
}

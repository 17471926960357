/**
 * Operational view for viewing and editing the reports system and menu
 */

import React        from 'react';
import { Logger }   from 'aws-amplify';
import View         from 'pac-responsive-ui-framework/core/View';
import Typography   from '@material-ui/core/Typography';
import AGGrid       from '../components/AGGrid';
import Service      from '../services/SLAMetric';
import Home         from './Home';

const logger = new Logger('OpsSLAMetric');

export default function OpsSLAMetric(props) {
    logger.debug(props);
    const service = new Service();
    
    var header =  <Typography variant="h5">{props.title}</Typography>;
    
    return (
        <View title={props.title} fixedContent={true}>
           <AGGrid service={service} recordID={props.recordID} columnDefs={service.getColumnDefs()} filename="sla_metrics" header={header} />
       </View>
    );
}
